import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Form, Button, Row, Col, Alert } from 'react-bootstrap';

const DiagnosticoPaciente = ({ pacienteId }) => {
  const [diagnostico, setDiagnostico] = useState(null); // Estado para almacenar el diagnóstico
  const [isEditing, setIsEditing] = useState(false); // Controla si estamos editando o no
  const [mensaje, setMensaje] = useState(''); // Para mostrar el resultado de la acción

  // Valores predeterminados para el diagnóstico
  const defaultDiagnostico = {
    dientes: 'Presentes',
    notas_dientes: '',
    caries: 'No',
    notas_caries: '',
    encias: 'Saludables',
    notas_encias: '',
    maloclusiones: 'Clase I',
    notas_maloclusiones: '',
    lesiones: 'Ninguna',
    notas_lesiones: '',
    hueso_maxilar: 'Sano',
    notas_hueso_maxilar: '',
  };

  // Cargar diagnóstico al montar el componente
  useEffect(() => {
    axios.get(`http://localhost:8000/api/obtener-diagnostico-paciente/${pacienteId}/`)
      .then((response) => {
        if (response.data.message || !response.data) {
          // No hay diagnóstico, habilitar modo edición con campos vacíos
          setIsEditing(true);
          setDiagnostico(defaultDiagnostico); // Establecer valores predeterminados
        } else {
          // Si hay diagnóstico, mostrar los datos
          setDiagnostico({
            ...defaultDiagnostico, // Usar los valores por defecto para asegurar que no haya valores `null` o `undefined`
            ...response.data, // Sobrescribir con los valores reales si los hay
          });
          setIsEditing(false);
        }
      })
      .catch((error) => {
        if (error.response && error.response.status === 404) {
          // Si el diagnóstico no existe (error 404), se puede crear uno
          setIsEditing(true);
          setDiagnostico(defaultDiagnostico); // Establecer valores predeterminados
        } else {
          // Otro tipo de error, mostrar mensaje de error
          console.error('Error al obtener diagnóstico:', error);
          setMensaje('Error al cargar el diagnóstico.');
        }
      });
  }, [pacienteId]);

  // Manejar la creación o edición del diagnóstico
  const handleSubmit = (e) => {
    e.preventDefault();

    const diagnosticoData = {
      ...diagnostico,
      paciente: pacienteId, // Asociar el diagnóstico con el paciente
    };

    axios.post(`http://localhost:8000/api/crear-actualizar-diagnostico-paciente/${pacienteId}/`, diagnosticoData)
      .then((response) => {
        setMensaje('Diagnóstico guardado con éxito.');
        setIsEditing(false); // Cambiar a modo no edición después de guardar
      })
      .catch((error) => {
        console.error('Error al guardar diagnóstico:', error.response.data);
        setMensaje(`Error al guardar el diagnóstico: ${JSON.stringify(error.response.data)}`);
      });
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setDiagnostico({
      ...diagnostico,
      [name]: value
    });
  };

  // Habilitar edición
  const handleEdit = () => {
    setIsEditing(true);
  };

  return (
    <div className="diagnostico-paciente">
      <h3 className="text-center">Diagnóstico del Paciente</h3>
      {mensaje && <Alert variant={mensaje.includes('éxito') ? 'success' : 'danger'}>{mensaje}</Alert>}
      
      {diagnostico && !isEditing ? (
        <div>
          <Row>
            <Col md={6}>
              <strong>Dientes:</strong> {diagnostico.dientes} <br />
              <strong>Notas:</strong> {diagnostico.notas_dientes || 'Ninguna'}
            </Col>
            <Col md={6}>
              <strong>Caries Visibles:</strong> {diagnostico.caries} <br />
              <strong>Notas:</strong> {diagnostico.notas_caries || 'Ninguna'}
            </Col>
          </Row>

          <Row className="mt-3">
            <Col md={6}>
              <strong>Estado de las Encías:</strong> {diagnostico.encias} <br />
              <strong>Notas:</strong> {diagnostico.notas_encias || 'Ninguna'}
            </Col>
            <Col md={6}>
              <strong>Maloclusiones:</strong> {diagnostico.maloclusiones} <br />
              <strong>Notas:</strong> {diagnostico.notas_maloclusiones || 'Ninguna'}
            </Col>
          </Row>

          <Row className="mt-3">
            <Col md={6}>
              <strong>Lesiones en la Mucosa Bucal:</strong> {diagnostico.lesiones} <br />
              <strong>Notas:</strong> {diagnostico.notas_lesiones || 'Ninguna'}
            </Col>
            <Col md={6}>
              <strong>Estado del Hueso Maxilar:</strong> {diagnostico.hueso_maxilar} <br />
              <strong>Notas:</strong> {diagnostico.notas_hueso_maxilar || 'Ninguna'}
            </Col>
          </Row>

          <Button variant="primary" className="mt-3" onClick={handleEdit}>
            Editar Diagnóstico
          </Button>
        </div>
      ) : (
        <Form onSubmit={handleSubmit}>
          <Row>
            <Col md={6}>
              <Form.Group controlId="dientes">
                <Form.Label>Dientes Presentes, Ausentes o Restaurados</Form.Label>
                <Form.Control
                  as="select"
                  name="dientes"
                  value={diagnostico?.dientes || ''}
                  onChange={handleInputChange}>
                  <option value="Presentes">Presentes</option>
                  <option value="Ausentes">Ausentes</option>
                  <option value="Restaurados">Restaurados</option>
                </Form.Control>
                <Form.Control
                  type="text"
                  name="notas_dientes"
                  placeholder="Notas"
                  value={diagnostico?.notas_dientes || ''}
                  onChange={handleInputChange}
                />
              </Form.Group>
            </Col>

            <Col md={6}>
              <Form.Group controlId="caries">
                <Form.Label>Caries Visibles</Form.Label>
                <Form.Control
                  as="select"
                  name="caries"
                  value={diagnostico?.caries || ''}
                  onChange={handleInputChange}>
                  <option value="Si">Si</option>
                  <option value="No">No</option>
                </Form.Control>
                <Form.Control
                  type="text"
                  name="notas_caries"
                  placeholder="Notas"
                  value={diagnostico?.notas_caries || ''}
                  onChange={handleInputChange}
                />
              </Form.Group>
            </Col>
          </Row>

          <Row>
            <Col md={6}>
              <Form.Group controlId="encias">
                <Form.Label>Estado de las Encías</Form.Label>
                <Form.Control
                  as="select"
                  name="encias"
                  value={diagnostico?.encias || ''}
                  onChange={handleInputChange}>
                  <option value="Saludables">Saludables</option>
                  <option value="Inflamadas">Inflamadas</option>
                  <option value="Recesión Gingival">Recesión Gingival</option>
                </Form.Control>
                <Form.Control
                  type="text"
                  name="notas_encias"
                  placeholder="Notas"
                  value={diagnostico?.notas_encias || ''}
                  onChange={handleInputChange}
                />
              </Form.Group>
            </Col>

            <Col md={6}>
              <Form.Group controlId="maloclusiones">
                <Form.Label>Maloclusiones</Form.Label>
                <Form.Control
                  as="select"
                  name="maloclusiones"
                  value={diagnostico?.maloclusiones || ''}
                  onChange={handleInputChange}>
                  <option value="Clase I">Clase I</option>
                  <option value="Clase II">Clase II</option>
                  <option value="Clase III">Clase III</option>
                </Form.Control>
                <Form.Control
                  type="text"
                  name="notas_maloclusiones"
                  placeholder="Notas"
                  value={diagnostico?.notas_maloclusiones || ''}
                  onChange={handleInputChange}
                />
              </Form.Group>
            </Col>
          </Row>

          <Row>
            <Col md={6}>
              <Form.Group controlId="lesiones">
                <Form.Label>Lesiones en la Mucosa Bucal</Form.Label>
                <Form.Control
                  as="select"
                  name="lesiones"
                  value={diagnostico?.lesiones || ''}
                  onChange={handleInputChange}>
                  <option value="Ninguna">Ninguna</option>
                  <option value="Úlceras">Úlceras</option>
                  <option value="Herpes">Herpes</option>
                </Form.Control>
                <Form.Control
                  type="text"
                  name="notas_lesiones"
                  placeholder="Notas"
                  value={diagnostico?.notas_lesiones || ''}
                  onChange={handleInputChange}
                />
              </Form.Group>
            </Col>

            <Col md={6}>
              <Form.Group controlId="hueso_maxilar">
                <Form.Label>Estado del Hueso Maxilar</Form.Label>
                <Form.Control
                  as="select"
                  name="hueso_maxilar"
                  value={diagnostico?.hueso_maxilar || ''}
                  onChange={handleInputChange}>
                  <option value="Sano">Sano</option>
                  <option value="Reabsorción">Reabsorción</option>
                  <option value="Otro">Otro</option>
                </Form.Control>
                <Form.Control
                  type="text"
                  name="notas_hueso_maxilar"
                  placeholder="Notas"
                  value={diagnostico?.notas_hueso_maxilar || ''}
                  onChange={handleInputChange}
                />
              </Form.Group>
            </Col>
          </Row>

          <Button variant="primary" type="submit" className="mt-3">
            {diagnostico ? 'Guardar Diagnóstico' : 'Editar Diagnóstico'}
          </Button>
        </Form>
      )}
    </div>
  );
};

export default DiagnosticoPaciente;

import React, { useState, useEffect } from 'react';
import { Table, Container, Row, Col, Spinner, Form, Button, Alert } from 'react-bootstrap';
import axios from 'axios';
import '../ListasRegistros.css'; 

const DentistasRegistrados = () => {
  const [dentistas, setDentistas] = useState([]);
  const [loading, setLoading] = useState(true);
  const [editingId, setEditingId] = useState(null);
  const [editableData, setEditableData] = useState({});
  const [successMessage, setSuccessMessage] = useState(false);

  useEffect(() => {
    const obtenerDentistas = async () => {
      try {
        const response = await axios.get('http://localhost:8000/api/obtener-dentistas/');
        setDentistas(response.data);
        setLoading(false);
      } catch (error) {
        console.error('Error al obtener los dentistas:', error);
        setLoading(false); 
      }
    };

    obtenerDentistas();
  }, []);

  const handleEdit = (dentista) => {
    setEditingId(dentista.id);
    setEditableData({ ...dentista });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setEditableData({ ...editableData, [name]: value });
  };

  const handleSave = async () => {
    try {
      await axios.put(`http://localhost:8000/api/editar-dentista/${editingId}/`, editableData);
      setDentistas((prev) =>
        prev.map((dentista) => (dentista.id === editingId ? editableData : dentista))
      );
      setEditingId(null);
      setSuccessMessage(true);
      setTimeout(() => setSuccessMessage(false), 3000); // Oculta el mensaje después de 3 segundos
    } catch (error) {
      console.error('Error al actualizar el dentista:', error);
      alert('Ocurrió un error al actualizar el dentista.');
    }
  };

  const renderRoles = (roles) => {
    return Object.keys(roles)
      .filter((key) => roles[key])
      .map((role) => role.charAt(0).toUpperCase() + role.slice(1))
      .join(', ');
  };

  const renderPassword = (password) => {
    return '•'.repeat(password.length);
  };

  return (
    <Container className="usuarios-registrados mt-5">
      <Row>
        <Col>
          <h2 className="text-center mb-4">Dentistas Registrados</h2>

          {/* Mensaje de éxito */}
          {successMessage && (
            <Alert variant="success" className="text-center animate-fade-in">
              Dentista actualizado con éxito.
            </Alert>
          )}

          {loading ? (
            <div className="spinner-container">
              <Spinner animation="border" role="status">
                <span className="visually-hidden">Cargando...</span>
              </Spinner>
            </div>
          ) : (
            <div className="table-responsive">
            <Table responsive bordered hover className="usuarios-tabla">
              <thead className="custom-thead">
                <tr>
                  <th>Nombre</th>
                  <th>Apellido</th>
                  <th>Correo Electrónico</th>
                  <th>Teléfono</th>
                  <th>Colegiado</th>
                  <th>Especialización</th>
                  <th>Nombre Usuario</th>
                  <th>Contraseña</th>
                  <th>Roles</th>
                  <th>Acciones</th>
                </tr>
              </thead>
              <tbody>
                {dentistas.length > 0 ? (
                  dentistas.map((dentista) => (
                    <tr key={dentista.id}>
                      <td>
                        {editingId === dentista.id ? (
                          <Form.Control
                            type="text"
                            name="nombre"
                            value={editableData.nombre}
                            onChange={handleChange}
                          />
                        ) : (
                          dentista.nombre
                        )}
                      </td>
                      <td>
                        {editingId === dentista.id ? (
                          <Form.Control
                            type="text"
                            name="apellido"
                            value={editableData.apellido}
                            onChange={handleChange}
                          />
                        ) : (
                          dentista.apellido
                        )}
                      </td>
                      <td>
                        {editingId === dentista.id ? (
                          <Form.Control
                            type="email"
                            name="correo"
                            value={editableData.correo}
                            onChange={handleChange}
                          />
                        ) : (
                          dentista.correo
                        )}
                      </td>
                      <td>
                        {editingId === dentista.id ? (
                          <Form.Control
                            type="tel"
                            name="telefono"
                            value={editableData.telefono}
                            onChange={handleChange}
                          />
                        ) : (
                          dentista.telefono
                        )}
                      </td>
                      <td>
                        {editingId === dentista.id ? (
                          <Form.Control
                            type="text"
                            name="colegiado"
                            value={editableData.colegiado}
                            onChange={handleChange}
                          />
                        ) : (
                          dentista.colegiado
                        )}
                      </td>
                      <td>
                        {editingId === dentista.id ? (
                          <Form.Control
                            type="text"
                            name="especializacion"
                            value={editableData.especializacion}
                            onChange={handleChange}
                          />
                        ) : (
                          dentista.especializacion
                        )}
                      </td>
                      <td>{dentista.nombre_usuario}</td>
                      <td>
                        {editingId === dentista.id ? (
                          <Form.Control
                            type="password"
                            name="contrasena"
                            value={editableData.contrasena}
                            onChange={handleChange}
                          />
                        ) : (
                          renderPassword(dentista.contrasena)
                        )}
                      </td>
                      <td>{renderRoles(dentista.roles)}</td>
                      <td>
                        {editingId === dentista.id ? (
                          <Button variant="success" onClick={handleSave}>
                            Guardar
                          </Button>
                        ) : (
                          <Button variant="warning" onClick={() => handleEdit(dentista)}>
                            Editar
                          </Button>
                        )}
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan="10" className="text-center">
                      No se han registrado dentistas.
                    </td>
                  </tr>
                )}
              </tbody>
            </Table>
            </div>
          )}
        </Col>
      </Row>
    </Container>
  );
};

export default DentistasRegistrados;









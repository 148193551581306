import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Table, Form, Container, Row, Col, Spinner, Button, Modal } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import '../ListasRegistros.css'; 

const FiltroPaciente = () => {
  const [pacientes, setPacientes] = useState([]);
  const [filtro, setFiltro] = useState('');
  const [loading, setLoading] = useState(true); // Para gestionar el estado de carga
  const [showModal, setShowModal] = useState(false);
  const [selectedPaciente, setSelectedPaciente] = useState(null);
  const [isEditing, setIsEditing] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    axios.get('http://localhost:8000/api/obtener-pacientes/')
      .then((response) => {
        setPacientes(response.data);
        setLoading(false); // Dejar de cargar cuando se obtienen los datos
      })
      .catch((error) => {
        console.error('Error al obtener pacientes:', error);
        setLoading(false);
      });
  }, []);

  const pacientesFiltrados = pacientes.filter((paciente) =>
    paciente.nombre.toLowerCase().includes(filtro.toLowerCase()) ||
    paciente.apellido.toLowerCase().includes(filtro.toLowerCase())
  );

  const handleShowModal = (paciente) => {
    setSelectedPaciente(paciente);
    setShowModal(true);
    setIsEditing(false);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setSelectedPaciente(null);
  };

  const handleEdit = () => {
    setIsEditing(true);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setSelectedPaciente({ ...selectedPaciente, [name]: value });
  };

  const handleSaveChanges = () => {
    if (!selectedPaciente?.id) {
      console.error('ID del paciente no encontrado');
      return;
    }
    axios.put(`http://localhost:8000/api/actualizar-paciente/${selectedPaciente.id}/`, selectedPaciente)
      .then((response) => {
        console.log('Paciente actualizado:', response.data);
        setShowModal(false);
      })
      .catch((error) => {
        console.error('Error al actualizar paciente:', error);
      });
  };

  const handleVerFicha = () => {
    if (selectedPaciente?.id) {
      navigate(`/ficha-paciente/${selectedPaciente.id}`);
    }
  };

  return (
    <Container className="usuarios-registrados mt-5">
      <Row>
        <Col>
          <h2 className="text-center mb-4">Pacientes Registrados</h2>
          <Form.Control
            type="text"
            placeholder="Buscar paciente por nombre o apellido"
            value={filtro}
            onChange={(e) => setFiltro(e.target.value)}
            className="mb-4"
          />
          
          {loading ? (
            <div className="spinner-container text-center">
              <Spinner animation="border" role="status">
                <span className="visually-hidden">Cargando...</span>
              </Spinner>
            </div>
          ) : (
            <div className="table-responsive">
              <Table responsive bordered hover className="usuarios-tabla">
                <thead className="custom-thead">
                  <tr>
                    <th>Nombre</th>
                    <th>Apellido</th>
                    <th>Correo Electrónico</th>
                    <th>Teléfono</th>
                    <th>Fecha de Nacimiento</th>
                    <th>Dirección</th>
                    <th>Género</th>
                    <th>Acciones</th>
                  </tr>
                </thead>
                <tbody>
                  {pacientesFiltrados.length > 0 ? (
                    pacientesFiltrados.map((paciente, index) => (
                      <tr key={paciente.id || `${paciente.nombre}-${index}`} onClick={() => handleShowModal(paciente)}>
                        <td>{paciente.nombre}</td>
                        <td>{paciente.apellido}</td>
                        <td>{paciente.email}</td>
                        <td>{paciente.telefono}</td>
                        <td>{paciente.fecha_nacimiento}</td>
                        <td>{paciente.direccion}</td>
                        <td>{paciente.genero}</td>
                        <td>
                          <Button variant="warning" onClick={() => handleShowModal(paciente)}>Editar</Button>
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan="8" className="text-center">No se han registrado pacientes.</td>
                    </tr>
                  )}
                </tbody>
              </Table>
            </div>
          )}
        </Col>
      </Row>

      <Modal show={showModal} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>Detalles del Paciente</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {selectedPaciente && (
            <Form>
              <Form.Group controlId="nombre">
                <Form.Label>Nombre</Form.Label>
                <Form.Control
                  type="text"
                  name="nombre"
                  value={selectedPaciente.nombre}
                  onChange={handleInputChange}
                  disabled={!isEditing}
                />
              </Form.Group>
              <Form.Group controlId="apellido">
                <Form.Label>Apellido</Form.Label>
                <Form.Control
                  type="text"
                  name="apellido"
                  value={selectedPaciente.apellido}
                  onChange={handleInputChange}
                  disabled={!isEditing}
                />
              </Form.Group>
              <Form.Group controlId="email">
                <Form.Label>Correo Electrónico</Form.Label>
                <Form.Control
                  type="text"
                  name="email"
                  value={selectedPaciente.telefono}
                  onChange={handleInputChange}
                  disabled={!isEditing}
                />
              </Form.Group>
              <Form.Group controlId="telefono">
                <Form.Label>Teléfono</Form.Label>
                <Form.Control
                  type="text"
                  name="telefono"
                  value={selectedPaciente.telefono}
                  onChange={handleInputChange}
                  disabled={!isEditing}
                />
              </Form.Group>
              <Form.Group controlId="telefono">
                <Form.Label>Fecha De Nacimiento</Form.Label>
                <Form.Control
                  type="text"
                  name="telefono"
                  value={selectedPaciente.telefono}
                  onChange={handleInputChange}
                  disabled={!isEditing}
                />
              </Form.Group>
              <Form.Group controlId="telefono">
                <Form.Label>Dirección</Form.Label>
                <Form.Control
                  type="text"
                  name="telefono"
                  value={selectedPaciente.telefono}
                  onChange={handleInputChange}
                  disabled={!isEditing}
                />
              </Form.Group>
              <Form.Group controlId="telefono">
                <Form.Label>Género</Form.Label>
                <Form.Control
                  type="text"
                  name="telefono"
                  value={selectedPaciente.telefono}
                  onChange={handleInputChange}
                  disabled={!isEditing}
                />
              </Form.Group>
              {/* Agregar otros campos si es necesario */}
            </Form>
          )}
        </Modal.Body>
        <Modal.Footer>
          {isEditing ? (
            <>
              <Button variant="success" onClick={handleSaveChanges}>Guardar</Button>
              <Button variant="secondary" onClick={() => setIsEditing(false)}>Cancelar</Button>
            </>
          ) : (
            <>
              <Button variant="warning" onClick={handleEdit}>Editar</Button>
              <Button variant="secondary" onClick={handleVerFicha}>Ver Ficha Completa</Button>
            </>
          )}
          <Button variant="danger" onClick={handleCloseModal}>Cerrar</Button>
        </Modal.Footer>
      </Modal>
    </Container>
  );
};

export default FiltroPaciente;

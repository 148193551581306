import React, { useState, useEffect } from 'react';
import { Form, FormControl, Button, Row, Col, Navbar, Nav, Container } from 'react-bootstrap';
import axios from 'axios';
import FichaPaciente from './FichaPaciente'; // Importar el componente de FichaPaciente
import HistorialPaciente from './HistorialPaciente'; // Importar el componente de HistorialPaciente
import './ResumenFichaPaciente.css'; // Archivo CSS para estilos personalizados
import DiagnosticoPaciente from './DiagnosticoPaciente';
import TratamientoPaciente from './TratamientoPaciente';
import RadiografiaPaciente from './RadiografiaPaciente';
import SaldoPaciente from '../Pagos/SaldoPaciente';

const ResumenFichaPaciente = () => {
  const [pacientes, setPacientes] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredPacientes, setFilteredPacientes] = useState([]);
  const [selectedPaciente, setSelectedPaciente] = useState(null); // Guardar el paciente seleccionado
  const [activeSection, setActiveSection] = useState(''); // Controlar la sección activa

  // Obtener lista de pacientes desde el backend
  useEffect(() => {
    const obtenerPacientes = async () => {
      try {
        const response = await axios.get('http://localhost:8000/api/obtener-pacientes/');
        setPacientes(response.data);
        setFilteredPacientes(response.data);
      } catch (error) {
        console.error('Error al obtener los pacientes:', error);
      }
    };
    obtenerPacientes();
  }, []);

  // Manejar la búsqueda
  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
    if (e.target.value === '') {
      setFilteredPacientes(pacientes);
    } else {
      const filtered = pacientes.filter((paciente) =>
        paciente.nombre.toLowerCase().includes(e.target.value.toLowerCase()) ||
        paciente.apellido.toLowerCase().includes(e.target.value.toLowerCase())
      );
      setFilteredPacientes(filtered);
    }
  };

  // Manejar la selección de un paciente
  const handleSelectPaciente = (paciente) => {
    setSelectedPaciente(paciente);
    setActiveSection('datosGenerales'); // Cambiar a la sección "Ficha General" al seleccionar el paciente
  };

  return (
    <Container className="resumen-ficha-container mt-5">
      {/* Filtro de búsqueda */}
      <Row className="mb-4">
        <Col>
          <Form className="d-flex justify-content-center">
            <FormControl
              type="search"
              placeholder="Buscar paciente por nombre o apellido"
              className="me-2 search-bar"
              value={searchTerm}
              onChange={handleSearch}
            />
            <Button variant="primary">Buscar</Button>
          </Form>
        </Col>
      </Row>

      {/* Lista de Pacientes */}
      <Row>
        <Col>
          <ul className="list-group">
            {filteredPacientes.length > 0 ? (
              filteredPacientes.map((paciente) => (
                <li
                  key={paciente.id}
                  className="list-group-item"
                  onClick={() => handleSelectPaciente(paciente)}
                >
                  {paciente.nombre} {paciente.apellido} - {paciente.correo}
                </li>
              ))
            ) : (
              <li className="list-group-item">No se encontraron pacientes.</li>
            )}
          </ul>
        </Col>
      </Row>

      {/* Navbar con opciones de ficha del paciente */}
      {selectedPaciente && (
        <>
          <Row className="mt-5">
            <Col>
              <Navbar bg="light" variant="light" expand="lg" className="patient-navbar">
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav">
                  <Nav className="mx-auto">
                    <Nav.Link onClick={() => setActiveSection('datosGenerales')}>
                      Datos Generales
                    </Nav.Link>
                    <Nav.Link onClick={() => setActiveSection('fichaGeneral')}>
                      Ficha General
                    </Nav.Link>
                    <Nav.Link onClick={() => setActiveSection('citas')}>
                      Citas
                    </Nav.Link>
                    <Nav.Link onClick={() => setActiveSection('historialMedico')}>
                      Historial Médico
                    </Nav.Link>
                    <Nav.Link onClick={() => setActiveSection('diagnostico')}>
                      Diagnóstico
                    </Nav.Link>
                    <Nav.Link onClick={() => setActiveSection('planTratamiento')}>
                      Plan de Tratamiento
                    </Nav.Link>
                    <Nav.Link onClick={() => setActiveSection('radiografias')}>
                      Radiografías
                    </Nav.Link>
                    <Nav.Link onClick={() => setActiveSection('saldo')}>Saldo</Nav.Link>
                    <Nav.Link onClick={() => setActiveSection('consentimiento')}>
                      Consentimiento
                    </Nav.Link>
                  </Nav>
                </Navbar.Collapse>
              </Navbar>
            </Col>
          </Row>

          {/* Renderización condicional de la ficha del paciente */}
          <Row className="mt-4">
            <Col>
              {activeSection === 'fichaGeneral' && (
                <FichaPaciente paciente={selectedPaciente} /> // Pasa el paciente seleccionado a FichaPaciente
              )}
              {activeSection === 'historialMedico' && (
                <HistorialPaciente pacienteId={selectedPaciente.id} /> // Pasa el ID del paciente seleccionado a HistorialPaciente
              )}
              {activeSection === 'diagnostico' && (
                <DiagnosticoPaciente pacienteId={selectedPaciente.id} /> // Pasa el ID del paciente seleccionado a Diagnostico
              )}
              {activeSection === 'planTratamiento' && (
                <TratamientoPaciente pacienteId={selectedPaciente.id} /> // Pasa el ID del paciente seleccionado a Plan de Tratamientos
              )}
              {activeSection === 'radiografias' && (
                <RadiografiaPaciente pacienteId={selectedPaciente.id} /> // Pasa el ID del paciente seleccionado a Radiografias
              )}
              {activeSection === 'saldo' && (
                <SaldoPaciente pacienteId={selectedPaciente.id} /> // Pasa el ID del paciente seleccionado a Radiografias
              )}
              
            </Col>
          </Row>
        </>
      )}
    </Container>
  );
};

export default ResumenFichaPaciente;

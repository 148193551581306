import React, { useState, useEffect } from 'react';
import { FaBars, FaUserMd, FaPlus, FaList, FaUser, FaCoins, FaCalendar } from 'react-icons/fa';
import { Button } from 'react-bootstrap';
import { useNavigate, useParams } from 'react-router-dom';
import { motion } from 'framer-motion';
import './Dashboard.css'; 
import RegistroDentista from './Doctores/RegistroDentista'; 
import DentistasRegistrados from './Doctores/DentistasRegistrados';
import RegistroPaciente from './Paciente/RegistroPaciente';
import FiltroPaciente from './Paciente/FiltroPaciente';
import ResumenFichaPaciente from './Paciente/ResumenFichaPaciente';
import PagosPaciente from './Pagos/PagosPaciente';
import SaldoPaciente from './Pagos/SaldoPaciente';
import CitasPaciente from './Citas/CitasPaciente';
import CitasAgendadas from './Citas/CitasAgendadas';
import RegistrarEmpleado from './Empleados/RegistrarEmpleado';
import ListaEmpleados from './Empleados/ListaEmpleados';
import CargoEmpleados from './Empleados/CargoEmpleados';


import menuAnimation from '../animaciones/menu.json';
import UseAnimations from "react-useanimations";
import menu from 'react-useanimations/lib/menu';



import GradualSpacingDemo from '../Componentes/GradualSpacing'; // Importar el componente GradualSpacing

const Dashboard = () => {
  const [isDentistaSubMenuOpen, setIsDentistaSubMenuOpen] = useState(false);
  const [isPacienteSubMenuOpen, setIsPacienteSubMenuOpen] = useState(false);
  const [isPagosSubMenuOpen, setIsPagosSubMenuOpen] = useState(false);
  const [isCitasSubMenuOpen, setIsCitasSubMenuOpen] = useState(false);
  const [isEmpleadosSubMenuOpen, setIsEmpleadosSubMenuOpen] = useState(false);
  const [menuOpen, setMenuOpen] = useState(true);
  const [selectedSection, setSelectedSection] = useState('');
  const navigate = useNavigate();
  const { username } = useParams();

  // Dividir el nombre y apellido del parámetro URL
  const [nombre, apellido] = username.includes('-') ? username.split('-') : [username, ''];

  const getFromLocalStorage = (key) => {
    const item = localStorage.getItem(key);
    if (item) {
      try {
        return JSON.parse(item);
      } catch (error) {
        console.error(`Error parsing ${key} from localStorage`, error);
        return null;
      }
    }
    return null;
  };
  console.log(menuAnimation);

  const isDentista = getFromLocalStorage('is_dentista');
  const isSuperuser = getFromLocalStorage('is_superuser');

  useEffect(() => {
    const isLoggedIn = localStorage.getItem('isLoggedIn');
    if (!isLoggedIn) {
      navigate('/login');
    }
  }, [navigate]);

  const handleLogout = () => {
    localStorage.clear();
    navigate('/');
  };

  const toggleDentistaSubMenu = () => setIsDentistaSubMenuOpen(!isDentistaSubMenuOpen);
  const togglePacienteSubMenu = () => setIsPacienteSubMenuOpen(!isPacienteSubMenuOpen);
  const togglePagosSubMenu = () => setIsPagosSubMenuOpen(!isPagosSubMenuOpen);
  const toggleCitasSubMenu = () => setIsCitasSubMenuOpen(!isCitasSubMenuOpen);
  const toggleEmpleadosSubMenu = () => setIsEmpleadosSubMenuOpen(!isEmpleadosSubMenuOpen);

  // Variants for Framer Motion animation
  const sidebarVariants = {
    open: {
      width: '250px',
      transition: { duration: 0.5 }
    },
    closed: {
      width: '80px',
      transition: { duration: 0.5 }
    }
  };

  const itemVariants = {
    open: {
      opacity: 1,
      x: 0,
      transition: { delay: 0.1 }
    },
    closed: {
      opacity: 0,
      x: -20,
      transition: { delay: 0.1 }
    }
  };

  return (
    <div className="dashboard">
      <header className="dashboard-header">
        <div className="header-content">
          {/* Uso del componente GradualSpacing para mostrar el texto animado */}
          <GradualSpacingDemo
  className="font-display text-center text-4xl font-bold tracking-[-0.1em] text-white dark:text-white md:text-7xl md:leading-[5rem]"
  text={
    isSuperuser
      ? `Bienvenid@, ${username}`
      : isDentista
      ? `Bienvenid@ Dr. ${nombre} ${apellido}`
      : `Bienvenid@, ${nombre} ${apellido}`
  }
/>


          <Button className="logout-btn" variant="danger" onClick={handleLogout}>
            Cerrar Sesión
          </Button>
        </div>
      </header>

      <div className="dashboard-layout">
        <motion.div
          className={`side-menu ${menuOpen ? 'open' : 'closed'}`}
          initial={false}
          animate={menuOpen ? "open" : "closed"}
          variants={sidebarVariants}
        >
          <div className="menu-toggle" onClick={() => setMenuOpen(!menuOpen)}>
          <UseAnimations animation={menu} size={35} strokeColor="white"/>
          </div>
          <nav className="menu-items">
            <ul>
              <motion.li variants={itemVariants} onClick={toggleDentistaSubMenu} className="menu-item">
                <FaUserMd /> {menuOpen && <span className="menu-text"> Dentistas</span>}
              </motion.li>
              {isDentistaSubMenuOpen && (
                <ul className="submenu">
                  <motion.li variants={itemVariants} className="submenu-item" onClick={() => setSelectedSection('registrarDentista')}>
                    <FaPlus /> {menuOpen && <span className="menu-text"> Registrar Dentista</span>}
                  </motion.li>
                  <motion.li variants={itemVariants} className="submenu-item" onClick={() => setSelectedSection('listaDentistas')}>
                    <FaList /> {menuOpen && <span className="menu-text">Dentistas</span>}
                  </motion.li>
                </ul>
              )}

              <motion.li variants={itemVariants} onClick={togglePacienteSubMenu} className="menu-item">
                <FaUser /> {menuOpen && <span className="menu-text">Pacientes</span>}
              </motion.li>
              {isPacienteSubMenuOpen && (
                <ul className="submenu">
                  <motion.li variants={itemVariants} className="submenu-item" onClick={() => setSelectedSection('registrarPaciente')}>
                    <FaPlus /> {menuOpen && <span className="menu-text">Registrar Paciente</span>}
                  </motion.li>
                  <motion.li variants={itemVariants} className="submenu-item" onClick={() => setSelectedSection('listaPacientes')}>
                    <FaList /> {menuOpen && <span className="menu-text">Pacientes</span>}
                  </motion.li>
                  <motion.li variants={itemVariants} className="submenu-item" onClick={() => setSelectedSection('fichasOdontologicas')}>
                    <FaList /> {menuOpen && <span className="menu-text">Fichas Odontológicas</span>}
                  </motion.li>
                </ul>
              )}

              <motion.li variants={itemVariants} onClick={togglePagosSubMenu} className="menu-item">
                <FaCoins /> {menuOpen && <span className="menu-text">Pagos</span>}
              </motion.li>
              {isPagosSubMenuOpen && (
                <ul className="submenu">
                  <motion.li variants={itemVariants} className="submenu-item" onClick={() => setSelectedSection('pagosPaciente')}>
                    <FaPlus /> {menuOpen && <span className="menu-text">Abonar a Paciente</span>}
                  </motion.li>
                  <motion.li variants={itemVariants} className="submenu-item" onClick={() => setSelectedSection('saldopaciente')}>
                    <FaList /> {menuOpen && <span className="menu-text">Saldos de Pacientes</span>}
                  </motion.li>
                </ul>
              )}

              <motion.li variants={itemVariants} onClick={toggleCitasSubMenu} className="menu-item">
                <FaCalendar /> {menuOpen && <span className="menu-text">Citas</span>}
              </motion.li>
              {isCitasSubMenuOpen && (
                <ul className="submenu">
                  <motion.li variants={itemVariants} className="submenu-item" onClick={() => setSelectedSection('crearcita')}>
                    <FaPlus /> {menuOpen && <span className="menu-text">Crear Cita</span>}
                  </motion.li>
                  <motion.li variants={itemVariants} className="submenu-item" onClick={() => setSelectedSection('citasagendadas')}>
                    <FaList /> {menuOpen && <span className="menu-text">Citas Agendadas</span>}
                  </motion.li>
                </ul>
              )}

              <motion.li variants={itemVariants} onClick={toggleEmpleadosSubMenu} className="menu-item">
                <FaCoins /> {menuOpen && <span className="menu-text">Empleados</span>}
              </motion.li>
              {isEmpleadosSubMenuOpen && (
                <ul className="submenu">
                  <motion.li variants={itemVariants} className="submenu-item" onClick={() => setSelectedSection('registrarEmpleado')}>
                    <FaPlus /> {menuOpen && <span className="menu-text">Registrar Empleado</span>}
                  </motion.li>
                  <motion.li variants={itemVariants} className="submenu-item" onClick={() => setSelectedSection('listaEmpleados')}>
                    <FaList /> {menuOpen && <span className="menu-text">Empleados</span>}
                  </motion.li>
                  <motion.li variants={itemVariants} className="submenu-item" onClick={() => setSelectedSection('crearroles')}>
                    <FaList /> {menuOpen && <span className="menu-text">Crear roles o cargos</span>}
                  </motion.li>
                </ul>
              )}
            </ul>
          </nav>
        </motion.div>

        <div className="main-content">
          {selectedSection === 'registrarDentista' && <RegistroDentista />}
          {selectedSection === 'listaDentistas' && <DentistasRegistrados />}

          {selectedSection === 'registrarPaciente' && <RegistroPaciente />}
          {selectedSection === 'listaPacientes' && <FiltroPaciente />}
          {selectedSection === 'fichasOdontologicas' && <ResumenFichaPaciente />}

          {selectedSection === 'pagosPaciente' && <PagosPaciente />}
          {selectedSection === 'saldopaciente' && <SaldoPaciente />}

          {selectedSection === 'crearcita' && <CitasPaciente />}
          {selectedSection === 'citasagendadas' && <CitasAgendadas />}

          {selectedSection === 'registrarEmpleado' && <RegistrarEmpleado />}
          {selectedSection === 'listaEmpleados' && <ListaEmpleados />}
          {selectedSection === 'crearroles' && <CargoEmpleados />}
        </div>
      </div>
    </div>
  );
};

export default Dashboard;

import React, { useState, useEffect } from 'react';
import { Table, Button, Form, Alert } from 'react-bootstrap';
import axios from 'axios';

const CargoEmpleados = () => {
  const [cargos, setCargos] = useState([]);
  const [editCargoId, setEditCargoId] = useState(null);
  const [editedNombre, setEditedNombre] = useState('');
  const [cargoNuevo, setCargoNuevo] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  // Obtener lista de cargos al cargar el componente
  useEffect(() => {
    const fetchCargos = async () => {
      try {
        const response = await axios.get('http://localhost:8000/cargos/');
        setCargos(response.data);
      } catch (error) {
        console.error('Error al obtener los cargos:', error);
      }
    };
    fetchCargos();
  }, []);

  // Manejar el cambio del input para el nuevo cargo
  const handleInputChange = (e) => {
    setCargoNuevo(e.target.value);
  };

  // Crear un nuevo cargo
  const handleCrearCargo = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post('http://localhost:8000/cargos/crear/', { nombre: cargoNuevo });
      setSuccessMessage('Cargo creado con éxito');
      setCargos([...cargos, response.data]);
      setCargoNuevo('');
    } catch (error) {
      setErrorMessage('Error al crear el cargo');
    }
  };

  // Manejar edición de cargo
  const handleModificar = (id, nombre) => {
    setEditCargoId(id);
    setEditedNombre(nombre);
  };

  // Guardar la edición de un cargo
  const handleGuardarEdicion = async (id) => {
    try {
      const response = await axios.put(`http://localhost:8000/cargos/${id}/editar/`, { nombre: editedNombre });
      const updatedCargos = cargos.map((cargo) => (cargo.id === id ? { ...cargo, nombre: editedNombre } : cargo));
      setCargos(updatedCargos);
      setEditCargoId(null);
      setSuccessMessage('Cargo modificado con éxito');
    } catch (error) {
      setErrorMessage('Error al modificar el cargo');
    }
  };

  // Cancelar la edición
  const handleCancelarEdicion = () => {
    setEditCargoId(null);
    setEditedNombre('');
  };

  // Eliminar un cargo
  const handleEliminar = async (id) => {
    try {
      await axios.delete(`http://localhost:8000/cargos/${id}/eliminar/`);
      const updatedCargos = cargos.filter((cargo) => cargo.id !== id);
      setCargos(updatedCargos);
      setSuccessMessage('Cargo eliminado con éxito');
    } catch (error) {
      setErrorMessage('Error al eliminar el cargo');
    }
  };

  return (
    <div className="form-container p-4" style={{ backgroundColor: '#f0f0f0', borderRadius: '8px' }}>
      <h3 style={{ color: '#c8dcf0' }}>Gestionar Roles o Cargos</h3>
      {successMessage && <Alert variant="success">{successMessage}</Alert>}
      {errorMessage && <Alert variant="danger">{errorMessage}</Alert>}

      {/* Formulario para crear un nuevo cargo */}
      <Form onSubmit={handleCrearCargo} className="mb-4">
        <Form.Group controlId="cargoNuevo">
          <Form.Label style={{ color: '#b4c8dc' }}>Nombre del Rol o Cargo</Form.Label>
          <Form.Control
            type="text"
            value={cargoNuevo}
            onChange={handleInputChange}
            placeholder="Ingrese el nombre del cargo"
            required
          />
        </Form.Group>
        <Button type="submit" style={{ backgroundColor: '#b4c8dc', borderColor: '#b4c8dc' }}>
          Crear Cargo
        </Button>
      </Form>

      {/* Tabla para listar los cargos */}
      <Table responsive hover>
        <thead style={{ backgroundColor: '#c8dcf0' }}>
          <tr>
            <th>Nombre del Rol o Cargo</th>
            <th>Acciones</th>
          </tr>
        </thead>
        <tbody>
          {cargos.map((cargo) => (
            <tr key={cargo.id}>
              <td>
                {editCargoId === cargo.id ? (
                  <Form.Control
                    type="text"
                    value={editedNombre}
                    onChange={(e) => setEditedNombre(e.target.value)}
                  />
                ) : (
                  cargo.nombre
                )}
              </td>
              <td>
                {editCargoId === cargo.id ? (
                  <>
                    <Button
                      variant="success"
                      className="me-2"
                      onClick={() => handleGuardarEdicion(cargo.id)}
                    >
                      Guardar
                    </Button>
                    <Button variant="secondary" onClick={handleCancelarEdicion}>
                      Cerrar
                    </Button>
                  </>
                ) : (
                  <>
                    <Button
                      variant="warning"
                      className="me-2"
                      onClick={() => handleModificar(cargo.id, cargo.nombre)}
                    >
                      Modificar
                    </Button>
                    <Button variant="danger" onClick={() => handleEliminar(cargo.id)}>
                      Eliminar
                    </Button>
                  </>
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
  );
};

export default CargoEmpleados;

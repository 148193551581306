import React from 'react';
import { Table, Button, Form, Row, Col } from 'react-bootstrap';

const ComponenteDetallePagos = ({
  detallePagos,
  tratamientos,
  selectedTratamiento,
  setSelectedTratamiento,
  pacienteNombre,
  pacienteApellido,
  handleModificar,
  handleGuardar,
  handleCerrar,
  handleEliminar,
  editPagoId,
  setEditPagoId,
  editAbono,
  setEditAbono,
  editTratamiento,
  setEditTratamiento,
}) => {
  const handleChangeTratamiento = (e) => {
    setSelectedTratamiento(e.target.value);
  };

  const handleTratamientoChange = (e) => {
    setEditTratamiento(e.target.value); // Actualiza el tratamiento seleccionado en edición
  };

  const handleAbonoChange = (e) => {
    setEditAbono(e.target.value); // Actualiza el monto en edición
  };

  // Función para formatear la fecha en formato dd/mm/aaaa
  const formatFecha = (fechaString) => {
    const fecha = new Date(fechaString);
    const dia = String(fecha.getDate()).padStart(2, '0'); // Asegura dos dígitos en el día
    const mes = String(fecha.getMonth() + 1).padStart(2, '0'); // Los meses en JavaScript empiezan desde 0
    const anio = fecha.getFullYear();
    return `${dia}/${mes}/${anio}`;
  };

  const pagosFiltrados = selectedTratamiento
    ? detallePagos.filter((pago) => Number(pago.tratamiento_id) === Number(selectedTratamiento))
    : detallePagos;

  return (
    <div className="detalle-pagos-tabla mt-4" style={{ backgroundColor: '#c8dcf0', padding: '20px', borderRadius: '8px' }}>
      <h4 className="mb-4" style={{ color: '#ffffff' }}>Detalle de Pagos</h4>

      <Form.Group as={Row} controlId="tratamientoSelect">
        <Form.Label column sm={2} style={{ color: '#b4c8dc' }}>Filtrar por Tratamiento:</Form.Label>
        <Col sm={10}>
          <Form.Control as="select" value={selectedTratamiento || ''} onChange={handleChangeTratamiento}>
            <option value="">Todos los Tratamientos</option>
            {tratamientos.map((tratamiento, index) => (
              <option key={tratamiento.id || index} value={tratamiento.id}>
                {tratamiento.tratamiento_nombre || 'Tratamiento sin nombre'}
              </option>
            ))}
          </Form.Control>
        </Col>
      </Form.Group>

      <Table striped bordered hover responsive>
        <thead>
          <tr style={{ backgroundColor: '#b4c8dc' }}>
            <th>Nombre Paciente</th>
            <th>Tratamiento</th>
            <th>Fecha</th>
            <th>Monto</th>
            <th>Saldo</th>
            <th>Acciones</th>
          </tr>
        </thead>
        <tbody>
          {pagosFiltrados.length > 0 ? (
            pagosFiltrados.reduce((accum, pago, index) => {
              const totalPagosPrevios = accum.totalPagado + pago.monto;
              const saldoRestante = pago.precio_tratamiento - totalPagosPrevios;

              return {
                totalPagado: totalPagosPrevios,
                rows: [
                  ...accum.rows,
                  <tr key={pago.id}>
                    <td>{pacienteNombre} {pacienteApellido}</td>
                    <td>
                      {editPagoId === pago.id ? (
                        <Form.Control
                          as="select"
                          value={editTratamiento || pago.tratamiento_id}
                          onChange={handleTratamientoChange}
                        >
                          {tratamientos.map((tratamiento) => (
                            <option key={tratamiento.id} value={tratamiento.id}>
                              {tratamiento.tratamiento_nombre || 'Tratamiento sin nombre'}
                            </option>
                          ))}
                        </Form.Control>
                      ) : (
                        pago.tratamiento_nombre
                      )}
                    </td>
                    <td>{formatFecha(pago.fecha)}</td> {/* Formatear la fecha */}
                    <td>
                      {editPagoId === pago.id ? (
                        <Form.Control
                          type="number"
                          value={editAbono || pago.monto}
                          onChange={handleAbonoChange}
                        />
                      ) : (
                        `Q ${pago.monto}`
                      )}
                    </td>
                    <td>Q {saldoRestante.toFixed(2)}</td>
                    <td>
                      {editPagoId === pago.id ? (
                        <>
                          <Button variant="success" onClick={() => handleGuardar(pago.id)}>Guardar</Button>{' '}
                          <Button variant="secondary" onClick={handleCerrar}>Cerrar</Button>
                        </>
                      ) : (
                        <>
                          <Button variant="warning" onClick={() => handleModificar(pago)}>Modificar</Button>{' '}
                          <Button variant="danger" onClick={() => handleEliminar(pago.id)}>Eliminar</Button>
                        </>
                      )}
                    </td>
                  </tr>
                ]
              };
            }, { totalPagado: 0, rows: [] }).rows
          ) : (
            <tr>
              <td colSpan="6" className="text-center">No hay pagos registrados para este tratamiento.</td>
            </tr>
          )}
        </tbody>
      </Table>
    </div>
  );
};

export default ComponenteDetallePagos;

import React, { useState } from 'react';
import { Form, Button, Row, Col, Container } from 'react-bootstrap';
import axios from 'axios';
import '../RegistrarInfo.css';
const RegistroDentista = () => {
  const [formData, setFormData] = useState({
    nombre: '',
    apellido: '',
    correo: '',
    telefono: '',
    colegiado: '',
    especializacion: '',
    nombre_usuario: '',
    contrasena: '',
    confirmarContrasena: '',
    roles: {
      propietario: false,
      administrador: false,
      supervisor: false,
      colaborador: false,
    },
  });

  const [errores, setErrores] = useState({});

  const validarTelefono = (telefono) => {
    const regexGuatemala = /^[23456]\d{7}$/;
    return regexGuatemala.test(telefono);
  };

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    if (type === 'checkbox') {
      setFormData({
        ...formData,
        roles: { ...formData.roles, [name]: checked },
      });
    } else {
      setFormData({ ...formData, [name]: value });
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!validarTelefono(formData.telefono)) {
      setErrores({ telefono: 'El número de teléfono no es válido para Guatemala.' });
      return;
    }

    axios.post('http://localhost:8000/api/registrar-dentista/', formData)
      .then((response) => {
        console.log(response.data);
        alert('Dentista registrado con éxito.');
      })
      .catch((error) => {
        console.error('Error al registrar el dentista:', error);
        alert('Ocurrió un error al registrar el dentista.');
      });
  };

  return (
    <Container className="registro-container">
      <Form onSubmit={handleSubmit} className="form-registro shadow p-4 rounded">
        <h2 className="text-center mb-4">Registrar Dentista</h2>
        <Row>
          <Col md={6}>
            <Form.Group controlId="nombre">
              <Form.Label>Nombre</Form.Label>
              <Form.Control
                type="text"
                name="nombre"
                placeholder="Ingrese el nombre"
                value={formData.nombre}
                onChange={handleChange}
                required
                className="input-text"
              />
            </Form.Group>
          </Col>
          <Col md={6}>
            <Form.Group controlId="apellido">
              <Form.Label>Apellido</Form.Label>
              <Form.Control
                type="text"
                name="apellido"
                placeholder="Ingrese el apellido"
                value={formData.apellido}
                onChange={handleChange}
                required
                className="input-text"
              />
            </Form.Group>
          </Col>
        </Row>

        <Row>
          <Col md={6}>
            <Form.Group controlId="correo">
              <Form.Label>Correo Electrónico</Form.Label>
              <Form.Control
                type="email"
                name="correo"
                placeholder="Ingrese el correo"
                value={formData.correo}
                onChange={handleChange}
                required
                className="input-text"
              />
            </Form.Group>
          </Col>
          <Col md={6}>
            <Form.Group controlId="telefono">
              <Form.Label>Teléfono</Form.Label>
              <Form.Control
                type="tel"
                name="telefono"
                placeholder="Ingrese el teléfono"
                value={formData.telefono}
                onChange={handleChange}
                required
                className={`input-text ${errores.telefono ? 'is-invalid' : ''}`}
              />
              {errores.telefono && <div className="invalid-feedback">{errores.telefono}</div>}
            </Form.Group>
          </Col>
        </Row>

        <Row>
          <Col md={6}>
            <Form.Group controlId="colegiado">
              <Form.Label>Número de Colegiado</Form.Label>
              <Form.Control
                type="text"
                name="colegiado"
                placeholder="Ingrese el número de colegiado"
                value={formData.colegiado}
                onChange={handleChange}
                required
                className="input-text"
              />
            </Form.Group>
          </Col>
          <Col md={6}>
            <Form.Group controlId="especializacion">
              <Form.Label>Especialización</Form.Label>
              <Form.Control
                as="select"
                name="especializacion"
                value={formData.especializacion}
                onChange={handleChange}
                required
                className="input-text"
              >
                <option value="">Seleccione especialización</option>
                <option value="General">General</option>
                <option value="Ortodoncia">Ortodoncia</option>
                <option value="Endodoncia">Endodoncia</option>
                <option value="Maxilofacial">Maxilofacial</option>
              </Form.Control>
            </Form.Group>
          </Col>
        </Row>

        <Row>
          <Col md={6}>
            <Form.Group controlId="nombre_usuario">
              <Form.Label>Nombre de Usuario</Form.Label>
              <Form.Control
                type="text"
                name="nombre_usuario"
                placeholder="Ingrese el nombre de usuario"
                value={formData.nombre_usuario}
                onChange={handleChange}
                required
                className="input-text"
              />
            </Form.Group>
          </Col>
        </Row>

        <Row>
          <Col md={6}>
            <Form.Group controlId="contrasena">
              <Form.Label>Contraseña</Form.Label>
              <Form.Control
                type="password"
                name="contrasena"
                placeholder="Ingrese la contraseña"
                value={formData.contrasena}
                onChange={handleChange}
                required
                className="input-text"
              />
            </Form.Group>
          </Col>
          <Col md={6}>
            <Form.Group controlId="confirmarContrasena">
              <Form.Label>Confirmar Contraseña</Form.Label>
              <Form.Control
                type="password"
                name="confirmarContrasena"
                placeholder="Confirme la contraseña"
                value={formData.confirmarContrasena}
                onChange={handleChange}
                required
                className="input-text"
              />
            </Form.Group>
          </Col>
        </Row>

        <h4 className="mt-4">Roles y Permisos</h4>
        <Row>
          <Col md={3}>
            <Form.Check
              type="checkbox"
              label="Propietario"
              name="propietario"
              checked={formData.roles.propietario}
              onChange={handleChange}
              className="checkbox-label"
            />
          </Col>
          <Col md={3}>
            <Form.Check
              type="checkbox"
              label="Administrador"
              name="administrador"
              checked={formData.roles.administrador}
              onChange={handleChange}
              className="checkbox-label"
            />
          </Col>
          <Col md={3}>
            <Form.Check
              type="checkbox"
              label="Supervisor"
              name="supervisor"
              checked={formData.roles.supervisor}
              onChange={handleChange}
              className="checkbox-label"
            />
          </Col>
          <Col md={3}>
            <Form.Check
              type="checkbox"
              label="Colaborador"
              name="colaborador"
              checked={formData.roles.colaborador}
              onChange={handleChange}
              className="checkbox-label"
            />
          </Col>
        </Row>

        <Button type="submit" className="btn-submit mt-4 w-100">
          Registrar Dentista
        </Button>
      </Form>
    </Container>
  );
};

export default RegistroDentista;















import React, { useState, useEffect, useRef } from 'react';
import { Container, Row, Col, Button, Form, Modal, Table, Carousel } from 'react-bootstrap';
import './RadiografiaPaciente.css'; // Estilos personalizados
import ReactToPrint from 'react-to-print';
import { FaDownload, FaUpload, FaPrint, FaTrash, FaEdit } from 'react-icons/fa'; // Importar solo los iconos necesarios
import axios from 'axios'; // Importar axios para las solicitudes HTTP

const RadiografiaPaciente = ({ pacienteId }) => {
  const [radiografias, setRadiografias] = useState([]); // Lista de radiografías
  const [selectedRadiografia, setSelectedRadiografia] = useState(null); // Radiografía seleccionada
  const componentRef = useRef(); // Definir la referencia para imprimir
  const [showModal, setShowModal] = useState(false); // Para subir radiografías
  const [showZoomModal, setShowZoomModal] = useState(false); // Para ver la radiografía en detalle
  const [newRadiografia, setNewRadiografia] = useState(null); // Nueva radiografía
  const [notas, setNotas] = useState(''); // Notas para la nueva radiografía
  const [isEditing, setIsEditing] = useState(false); // Estado para controlar el modo de edición
  const [zoom, setZoom] = useState(false); // Estado para manejar el zoom en la imagen

  // Cargar las radiografías del paciente cuando el componente se monte
  useEffect(() => {
    axios.get(`http://localhost:8000/api/radiografias/${pacienteId}/`)
      .then(response => {
        const radiografiasConUrl = response.data.map(radiografia => ({
          ...radiografia,
          src: `http://localhost:8000${radiografia.imagen}`, // Ajusta esto según tu configuración del backend
        }));
        const radiografiasOrdenadas = radiografiasConUrl.sort((a, b) => new Date(b.fecha_tomada) - new Date(a.fecha_tomada));
        setRadiografias(radiografiasOrdenadas);
      })
      .catch(error => {
        console.error('Error al obtener las radiografías:', error);
      });
  }, [pacienteId]);

  // Función para abrir modal de detalles
  const handleRadiografiaClick = (radiografia) => {
    setSelectedRadiografia(radiografia);
    setNotas(radiografia.notas); // Inicializa las notas con las actuales
    setShowZoomModal(true);
  };

  // Función para manejar el zoom
  const handleZoomClick = () => {
    setZoom(!zoom); // Cambiar el estado del zoom
  };

  return (
    <Container fluid className="radiografia-container" ref={componentRef} style={{ backgroundColor: '#f0f0f0', padding: '20px', borderRadius: '8px' }}>
      <h3 className="text-center mb-4" style={{ color: '#b4c8dc' }}>Ficha de Radiografía</h3>

      {/* Carrusel de Radiografías Recientes */}
      <Carousel>
        <Carousel.Item>
          <Row>
            {radiografias.map((radiografia) => (
              <Col key={radiografia.id} md={4}>
                <div className="radiografia-thumbnail" style={{ padding: '5px', borderRadius: '8px', backgroundColor: '#ffffff', boxShadow: '0 2px 5px rgba(0,0,0,0.1)', cursor: 'pointer' }}>
                  <img
                    className="d-block w-100"
                    src={radiografia.src}
                    alt={`Radiografía tomada el ${radiografia.fecha_tomada}`}
                    onClick={() => handleRadiografiaClick(radiografia)}
                    style={{ height: '300px', objectFit: 'cover', borderRadius: '8px' }}
                  />
                </div>
              </Col>
            ))}
          </Row>
        </Carousel.Item>
      </Carousel>

      {/* Modal de zoom para ver la radiografía en detalle */}
      {selectedRadiografia && (
        <Modal show={showZoomModal} onHide={() => setShowZoomModal(false)} size="lg">
          <Modal.Header closeButton style={{ backgroundColor: '#c8dcf0' }}>
            <Modal.Title>Detalles de la radiografía</Modal.Title>
          </Modal.Header>
          <Modal.Body style={{ backgroundColor: '#f0f0f0' }}>
            <p>Fecha de subida: {selectedRadiografia.fecha_tomada}</p>
            <div
              className={zoom ? 'zoomed-image' : 'normal-image'}
              onClick={handleZoomClick} // Cambia entre zoom y normal al hacer clic
              style={{ cursor: zoom ? 'zoom-out' : 'zoom-in' }}
            >
              <img
                src={selectedRadiografia.src}
                alt="Radiografía detallada"
                className="img-fluid"
                style={{ borderRadius: '8px', boxShadow: '0 2px 5px rgba(0,0,0,0.1)' }}
              />
            </div>
            <Form.Group controlId="formNotas">
              <Form.Label>Notas:</Form.Label>
              <Form.Control
                as="textarea"
                rows={3}
                value={notas}
                onChange={(e) => setNotas(e.target.value)}
                readOnly={!isEditing} // Hacer que el campo sea editable solo en modo edición
              />
            </Form.Group>
          </Modal.Body>
          <Modal.Footer style={{ backgroundColor: '#c8dcf0' }}>
            <Button variant="secondary" onClick={() => setShowZoomModal(false)} style={{ borderRadius: '20px' }}>Cerrar</Button>
            {isEditing ? (
              <Button variant="primary" style={{ borderRadius: '20px', backgroundColor: '#b4c8dc', borderColor: '#b4c8dc' }}>Guardar</Button>
            ) : (
              <Button variant="warning" onClick={() => setIsEditing(true)} style={{ borderRadius: '20px' }}>Editar</Button>
            )}
          </Modal.Footer>
        </Modal>
      )}

      {/* Historial de radiografías */}
      <div className="historial mt-4" style={{ backgroundColor: '#c8dcf0', padding: '20px', borderRadius: '8px' }}>
        <h4>Historial de Radiografías</h4>
        <Table striped bordered hover>
          <thead>
            <tr>
              <th>Radiografía</th>
              <th>Fecha</th>
              <th>Notas</th>
              <th>Acciones</th>
            </tr>
          </thead>
          <tbody>
            {radiografias.map((radiografia) => (
              <tr key={radiografia.id}>
                <td onClick={() => handleRadiografiaClick(radiografia)}>
                  <img 
                    src={radiografia.src} 
                    alt="Radiografía" 
                    style={{ width: '100px', cursor: 'pointer', borderRadius: '8px' }} 
                  />
                </td>
                <td>{radiografia.fecha_tomada}</td>
                <td>{radiografia.notas}</td>
                <td>
                  <Button 
                    variant="warning" 
                    className="mr-2"
                    style={{ borderRadius: '20px' }}
                  >
                    <FaEdit /> Editar
                  </Button>
                  <Button 
                    variant="danger"
                    style={{ borderRadius: '20px' }}
                  >
                    <FaTrash /> Eliminar
                  </Button>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
    </Container>
  );
};

export default RadiografiaPaciente;

import React, { useState, useEffect } from 'react';
import { Form, Button, Row, Col, Table, Alert, Container, Modal } from 'react-bootstrap';
import axios from 'axios';
import './TratamientoPaciente.css'; // Archivo CSS para estilos personalizados
import { toast, ToastContainer } from 'react-toastify'; // Importar ToastContainer y toast
import 'react-toastify/dist/ReactToastify.css';

const TratamientoPaciente = ({ pacienteId }) => {
  const [tratamientos, setTratamientos] = useState([]);
  const [tipoTratamiento, setTipoTratamiento] = useState('');
  const [cantidad, setCantidad] = useState(1);
  const [precioUnitario, setPrecioUnitario] = useState(300); 
  const [notasTratamiento, setNotasTratamiento] = useState('');
  const [total, setTotal] = useState(0); 
  const [mostrarCampoNuevoTratamiento, setMostrarCampoNuevoTratamiento] = useState(false);
  const [nuevoTratamiento, setNuevoTratamiento] = useState('');
  const [nuevoPrecio, setNuevoPrecio] = useState(0);
  const [mensaje, setMensaje] = useState('');
  const [guardarParaTodos, setGuardarParaTodos] = useState(false);
  const [mostrarModal, setMostrarModal] = useState(false);
  const [mostrarModalModificar, setMostrarModalModificar] = useState(false); 
  const [mostrarModalEliminar, setMostrarModalEliminar] = useState(false); 
  const [tratamientoSeleccionado, setTratamientoSeleccionado] = useState(null); 
  const [nuevoPrecioModal, setNuevoPrecioModal] = useState(precioUnitario); 
  const [tratamientosDisponibles, setTratamientosDisponibles] = useState([]);
  const [mostrarModalNuevoTratamiento, setMostrarModalNuevoTratamiento] = useState(false);
  
  const [pacienteNombre, setPacienteNombre] = useState('');
  const [pacienteApellido, setPacienteApellido] = useState('');

  useEffect(() => {
    fetchPacienteInfo();
    fetchTratamientos();
    fetchTiposTratamiento();
  }, [pacienteId]);

  useEffect(() => {
    // Calcular el total y asegurarse de que siempre sea un número
    const nuevoTotal = tratamientos.reduce((acc, tratamiento) => {
      const subtotal = Number(tratamiento.subtotal) || 0; // Garantizar que subtotal sea un número
      return acc + subtotal;
    }, 0);
    setTotal(nuevoTotal);
  }, [tratamientos]);

  const fetchPacienteInfo = () => {
    axios.get(`http://localhost:8000/api/obtener-paciente/${pacienteId}/`)
      .then((response) => {
        setPacienteNombre(response.data.nombre);
        setPacienteApellido(response.data.apellido);
      })
      .catch((error) => {
        console.error('Error al obtener la información del paciente:', error);
      });
  };

  const fetchTratamientos = () => {
    axios.get(`http://localhost:8000/api/obtener-tratamientos-paciente/${pacienteId}/`)
      .then((response) => {
        setTratamientos(response.data.tratamientos || []);
      })
      .catch((error) => {
        console.error('Error al obtener los tratamientos:', error);
      });
  };

  const fetchTiposTratamiento = () => {
    axios.get('http://localhost:8000/api/tipos-tratamiento/')
      .then((response) => {
        console.log('Tratamientos recibidos:', response.data);
        const tratamientosOrdenados = response.data.sort((a, b) => a.nombre.localeCompare(b.nombre));
        setTratamientosDisponibles(tratamientosOrdenados);
      })
      .catch((error) => {
        console.error('Error al obtener los tipos de tratamiento:', error);
      });
  };

  const handleAgregarNuevoTratamiento = () => {
    // Verificar si el tratamiento ya existe
    const tratamientoExiste = tratamientosDisponibles.some(
      (trat) => trat.nombre.toLowerCase() === nuevoTratamiento.toLowerCase()
    );

    if (tratamientoExiste) {
      // Mostrar una notificación si el tratamiento ya existe
      toast.error('El tratamiento ya existe en la lista.', {
        position: "top-center",
        autoClose: 3000, // Cierra automáticamente después de 3 segundos
        hideProgressBar: true,
        pauseOnHover: false,
        draggable: false,
      });
      return;
    }

    if (nuevoTratamiento && nuevoPrecio > 0) {
      axios.post('http://localhost:8000/api/tipos-tratamiento/agregar/', {
        nombre: nuevoTratamiento,
        precio: nuevoPrecio,
      })
      .then((response) => {
        const nuevosTratamientos = [...tratamientosDisponibles, response.data];
        const tratamientosOrdenados = nuevosTratamientos.sort((a, b) => a.nombre.localeCompare(b.nombre));
        setTratamientosDisponibles(tratamientosOrdenados);
        setNuevoTratamiento('');
        setNuevoPrecio('');
        setMostrarModalNuevoTratamiento(false);
      })
      .catch((error) => {
        console.error('Error al agregar el nuevo tratamiento:', error);
      });
    } else {
      toast.warn('Por favor ingresa un nombre y precio válido para el tratamiento.', {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: true,
        pauseOnHover: false,
        draggable: false,
      });
    }
  };

  const agregarTratamiento = () => {
    if (tipoTratamiento && cantidad > 0 && precioUnitario > 0) {
      const subtotal = cantidad * precioUnitario;
      const nuevoTratamiento = { 
        tipo_tratamiento: tipoTratamiento, 
        cantidad, 
        precio_unitario: precioUnitario, 
        subtotal,
        notas: notasTratamiento 
      };

      axios.post(`http://localhost:8000/api/guardar-tratamiento-paciente/${pacienteId}/`, {
        tratamientos: [nuevoTratamiento],
        guardarParaTodos,
        pacienteId
      })
      .then(response => {
        setMensaje('Nuevo tratamiento agregado con éxito.');
        fetchTratamientos(); 
      })
      .catch(error => {
        setMensaje('Error al agregar el tratamiento.');
      });

      limpiarFormulario();
    } else {
      setMensaje('Por favor completa todos los campos.');
    }
  };

  const confirmarEliminarTratamiento = (tratamiento) => {
    setTratamientoSeleccionado(tratamiento);
    setMostrarModalEliminar(true); 
  };

  const eliminarTratamiento = () => {
    if (tratamientoSeleccionado) {
      axios.delete(`http://localhost:8000/api/eliminar-tratamiento/${tratamientoSeleccionado.id}/`)
        .then(response => {
          setMensaje('Tratamiento eliminado con éxito.');
          fetchTratamientos(); 
        })
        .catch(error => {
          setMensaje('Error al eliminar el tratamiento.');
        });
      setMostrarModalEliminar(false);
    }
  };

  const modificarTratamiento = (tratamiento) => {
    setTratamientoSeleccionado(tratamiento);
    setNuevoPrecioModal(tratamiento.precio_unitario);
    setCantidad(tratamiento.cantidad);
    setMostrarModalModificar(true);
  };

  const guardarModificacion = () => {
    if (tratamientoSeleccionado) {
      const datosModificados = {
        cantidad,
        precio_unitario: nuevoPrecioModal,
        notas: tratamientoSeleccionado.notas
      };
  
      console.log('Datos a enviar para la modificación:', datosModificados); // Verifica los datos
  
      axios.put(`http://localhost:8000/api/modificar-tratamiento/${tratamientoSeleccionado.id}/`, datosModificados)
        .then(response => {
          setMensaje('Tratamiento modificado con éxito.');
          fetchTratamientos(); 
        })
        .catch(error => {
          setMensaje('Error al modificar el tratamiento.');
        });
      setMostrarModalModificar(false);
    }
  };
  

  const handlePrecioDoubleClick = () => {
    setNuevoPrecioModal(precioUnitario);
    setMostrarModal(true);
  };

  const handleModalGuardar = (permanente) => {
    if (nuevoPrecioModal > 0) {
      setPrecioUnitario(nuevoPrecioModal);
      if (permanente) {
        const updatedTratamientosDisponibles = tratamientosDisponibles.map((trat) =>
          trat.nombre === tipoTratamiento ? { ...trat, precio: nuevoPrecioModal } : trat
        );
        setTratamientosDisponibles(updatedTratamientosDisponibles);
      }
    }
    setMostrarModal(false);
  };

  const agregarNuevoTratamiento = () => {
    if (nuevoTratamiento && nuevoPrecio > 0) {
      const nuevoTrat = { nombre: nuevoTratamiento, precio: nuevoPrecio };
      setTratamientosDisponibles([...tratamientosDisponibles, nuevoTrat]);
      setNuevoTratamiento('');
      setNuevoPrecio(0);
      setMensaje('Nuevo tratamiento agregado a la lista.');
    } else {
      setMensaje('Por favor ingresa un tratamiento y un precio válido.');
    }
  };

  const limpiarFormulario = () => {
    setTipoTratamiento('');
    setCantidad(1);
    setPrecioUnitario(300); 
    setNotasTratamiento('');
    setMostrarCampoNuevoTratamiento(false);
    setGuardarParaTodos(false);
    setMensaje('');
  };

  return (
    <Container className="tratamiento-paciente-container mt-5">
      <h3 className="text-center">Ficha de Tratamiento del Paciente</h3>
      <h5>Paciente: {pacienteNombre}{" "}{pacienteApellido} </h5>
      {mensaje && (
        <Alert variant={mensaje.includes('éxito') ? 'success' : 'danger'}>
          {mensaje}
        </Alert>
      )}

      <Form className="mb-4">
        <Row>
          <Col md={6}>
            <Form.Group controlId="tipoTratamiento">
              <Form.Label>Tipo de Tratamiento</Form.Label>
              <Form.Control
  as="select"
  value={tipoTratamiento}
  onChange={(e) => {
    if (e.target.value === 'nuevo') {
      setMostrarModalNuevoTratamiento(true);
    } else {
      const selectedTratamiento = tratamientosDisponibles.find(trat => trat.nombre === e.target.value);
      setTipoTratamiento(e.target.value);
      setPrecioUnitario(selectedTratamiento ? selectedTratamiento.precio : 300);
    }
  }}
>
  <option value="">Seleccionar...</option>
  {tratamientosDisponibles.length > 0 ? (
    tratamientosDisponibles.map((tratamiento) => (
      <option key={tratamiento.id} value={tratamiento.nombre}>
        {tratamiento.nombre}
      </option>
    ))
  ) : (
    <option value="">No hay tratamientos disponibles</option>
  )}
  <option value="nuevo">Agregar nuevo tratamiento...</option>
</Form.Control>
            </Form.Group>
          </Col>

          <Col md={3}>
            <Form.Group controlId="cantidad">
              <Form.Label>Cantidad</Form.Label>
              <Form.Control
                type="number"
                value={cantidad}
                min="1"
                onChange={(e) => setCantidad(parseInt(e.target.value))}
              />
            </Form.Group>
          </Col>

          <Col md={3}>
            <Form.Group controlId="precioUnitario">
              <Form.Label>Precio Unitario (Q)</Form.Label>
              <Form.Control
                type="number"
                value={precioUnitario}
                onDoubleClick={handlePrecioDoubleClick} 
                readOnly
              />
            </Form.Group>
          </Col>

          <Col md={12} className="mt-3">
            <Form.Group controlId="notasTratamiento">
              <Form.Label>Notas</Form.Label>
              <Form.Control
                as="textarea"
                rows={2}
                value={notasTratamiento}
                onChange={(e) => setNotasTratamiento(e.target.value)}
              />
            </Form.Group>
          </Col>
        </Row>

        <Button className="mt-3" onClick={agregarTratamiento}>Agregar Tratamiento</Button>
      </Form>

      <Table striped bordered hover className="mt-3">
        <thead>
          <tr>
            <th>Tipo de Tratamiento</th>
            <th>Cantidad</th>
            <th>Precio Unitario (Q)</th>
            <th>Subtotal (Q)</th>
            <th>Notas</th>
            <th>Acciones</th>
          </tr>
        </thead>
        <tbody>
          {tratamientos.map((tratamiento, idx) => (
            <tr key={idx}>
              <td>{tratamiento.tipo_tratamiento}</td>
              <td>{tratamiento.cantidad}</td>
              <td>{tratamiento.precio_unitario}</td>
              <td>{tratamiento.subtotal}</td>
              <td>{tratamiento.notas || 'Sin notas'}</td>
              <td>
                <Button
                  variant="warning"
                  onClick={() => modificarTratamiento(tratamiento)}
                  className="mr-2"
                >
                  Modificar
                </Button>
                <Button
                  variant="danger"
                  onClick={() => confirmarEliminarTratamiento(tratamiento)}
                >
                  Eliminar
                </Button>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>

      <h4 className="text-right">Total: Q{Number(total).toFixed(2)}</h4>





      {/* Modal para agregar nuevo tratamiento */}
      <Modal show={mostrarModalNuevoTratamiento} onHide={() => setMostrarModalNuevoTratamiento(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Agregar Nuevo Tratamiento</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group controlId="nuevoTratamiento">
            <Form.Label>Nombre del Tratamiento</Form.Label>
            <Form.Control
              type="text"
              value={nuevoTratamiento}
              onChange={(e) => setNuevoTratamiento(e.target.value)}
            />
          </Form.Group>
          <Form.Group controlId="nuevoPrecio">
            <Form.Label>Precio</Form.Label>
            <Form.Control
              type="number"
              value={nuevoPrecio}
              onChange={(e) => setNuevoPrecio(e.target.value)}
            />
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setMostrarModalNuevoTratamiento(false)}>Cancelar</Button>
          <Button variant="primary" onClick={handleAgregarNuevoTratamiento}>Agregar</Button>
        </Modal.Footer>
      </Modal>

      {/* Modales y botones adicionales... */}
      <Modal show={mostrarModal} onHide={() => setMostrarModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Modificar Precio Unitario</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Introduce el nuevo precio unitario para "{tipoTratamiento}":</p>
          <Form.Control
            type="number"
            value={nuevoPrecioModal}
            onChange={(e) => setNuevoPrecioModal(parseFloat(e.target.value))}
          />
          <p>¿Deseas modificar el precio permanentemente o solo para este paciente?</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => handleModalGuardar(false)}>Solo para este paciente</Button>
          <Button variant="primary" onClick={() => handleModalGuardar(true)}>Permanente</Button>
        </Modal.Footer>
      </Modal>

      {/* Modal para modificar tratamiento */}
      <Modal show={mostrarModalModificar} onHide={() => setMostrarModalModificar(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Modificar Tratamiento</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group controlId="cantidadModificar">
            <Form.Label>Cantidad</Form.Label>
            <Form.Control
              type="number"
              value={cantidad}
              onChange={(e) => setCantidad(parseInt(e.target.value))}
            />
          </Form.Group>
          <Form.Group controlId="nuevoPrecioModificar">
            <Form.Label>Nuevo Precio Unitario</Form.Label>
            <Form.Control
              type="number"
              value={nuevoPrecioModal}
              onChange={(e) => setNuevoPrecioModal(parseFloat(e.target.value))}
            />
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={guardarModificacion}>
            Guardar Cambios
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Modal de confirmación para eliminar tratamiento */}
      <Modal show={mostrarModalEliminar} onHide={() => setMostrarModalEliminar(false)} animation={true}>
        <Modal.Header closeButton>
          <Modal.Title>Confirmar Eliminación</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>¿Estás seguro de que deseas eliminar el tratamiento "<strong>{tratamientoSeleccionado?.tipo_tratamiento}</strong>"?</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setMostrarModalEliminar(false)}>Cancelar</Button>
          <Button variant="danger" onClick={eliminarTratamiento}>Eliminar</Button>
        </Modal.Footer>
      </Modal>

      <ToastContainer />
    </Container>
  );
};

export default TratamientoPaciente;

import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Form, Button, Spinner, Alert } from 'react-bootstrap';
import axios from 'axios';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import './CitasPaciente.css';
import CitasAgendadas from './CitasAgendadas';

const CitasPaciente = () => {
  const [pacientes, setPacientes] = useState([]);
  const [odontologos, setOdontologos] = useState([]); // Estado para odontólogos
  const [selectedOdontologo, setSelectedOdontologo] = useState(''); // Odontólogo seleccionado
  const [tipoCita, setTipoCita] = useState('');
  const [fechaCita, setFechaCita] = useState(new Date());
  const [horaInicio, setHoraInicio] = useState('');
  const [horaFin, setHoraFin] = useState('');
  const [selectedPaciente, setSelectedPaciente] = useState(''); // Paciente seleccionado
  const [pacienteInfo, setPacienteInfo] = useState({}); // Información del paciente
  const [loading, setLoading] = useState(false); // Estado para la animación de carga
  const [success, setSuccess] = useState(false); // Estado para indicar el éxito de la cita
  const [errorTraslape, setErrorTraslape] = useState(false); // Estado para traslapes de citas
  const [filtroPaciente, setFiltroPaciente] = useState(''); // Filtro de búsqueda de pacientes

  useEffect(() => {
    // Obtener pacientes desde el backend
    axios.get('http://localhost:8000/api/obtener-pacientes/')
      .then((response) => setPacientes(response.data))
      .catch((error) => console.error('Error al obtener pacientes:', error));

    // Obtener odontólogos desde el backend
    axios.get('http://localhost:8000/api/obtener-dentistas/')
      .then((response) => setOdontologos(response.data))
      .catch((error) => console.error('Error al obtener odontólogos:', error));
  }, []);

  const handlePacienteSelect = (paciente) => {
    setSelectedPaciente(paciente.id);
    setPacienteInfo(paciente);

    if (paciente.odontologo) {
      setSelectedOdontologo(paciente.odontologo.id);
    } else {
      setSelectedOdontologo(''); // Si no tiene odontólogo, reiniciar el valor
    }
  };

  const handleCitaSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setErrorTraslape(false); // Reiniciar el estado de traslape

    try {
      const traslapeResponse = await axios.post('http://localhost:8000/api/verificar-traslape/', {
        paciente: selectedPaciente,
        tipo_cita: tipoCita,
        fecha_cita: fechaCita.toISOString().split('T')[0],
        hora_inicio: horaInicio,
        hora_fin: horaFin,
        odontologo: selectedOdontologo,
      });

      if (traslapeResponse.data.traslape) {
        setErrorTraslape(true); // Mostrar mensaje de error si hay traslape
      } else {
        const response = await axios.post('http://localhost:8000/api/agendar-cita/', {
          paciente: selectedPaciente,
          tipo_cita: tipoCita,
          fecha_cita: fechaCita.toISOString().split('T')[0],  // Formato de fecha
          hora_inicio: horaInicio,
          hora_fin: horaFin,
          odontologo: selectedOdontologo,  // Odontólogo seleccionado
        });
        setSuccess(true);
        setTimeout(() => setSuccess(false), 3000); // Animación de éxito durante 3 segundos
        console.log('Cita agendada con éxito:', response.data);
      }
    } catch (error) {
      console.error('Error al verificar o agendar la cita:', error);
    } finally {
      setLoading(false);
    }
  };

  // Filtrar pacientes según el filtro de búsqueda
  const pacientesFiltrados = pacientes.filter((paciente) =>
    `${paciente.nombre} ${paciente.apellido}`.toLowerCase().includes(filtroPaciente.toLowerCase())
  );

  // Generar los intervalos de tiempo de 15 minutos
  const generarHorasIntervalo = () => {
    const horas = [];
    for (let h = 8; h < 18; h++) {
      for (let m = 0; m < 60; m += 15) {
        const hora = `${h.toString().padStart(2, '0')}:${m.toString().padStart(2, '0')}`;
        horas.push(hora);
      }
    }
    return horas;
  };

  return (
    <Container className="citas-container mt-4">
      <Form onSubmit={handleCitaSubmit}>
      <h3 className="text-center mb-4" style={{ color: '#b4c8dc' }}>Agendar Cita</h3>
        <Row className="mb-3">
          <Col md={6}>
            <Form.Group>
              <Form.Label style={{ color: '#b4c8dc' }}>Paciente</Form.Label>
              {/* Input de búsqueda */}
              <Form.Control
                type="text"
                placeholder="Buscar paciente..."
                value={filtroPaciente}
                onChange={(e) => setFiltroPaciente(e.target.value)}
              />
              {/* Mostrar pacientes filtrados */}
              <div className="pacientes-lista mt-2">
                {pacientesFiltrados.map((paciente) => (
                  <div
                    key={paciente.id}
                    className="paciente-item"
                    onClick={() => handlePacienteSelect(paciente)}
                    style={{
                      cursor: 'pointer',
                      padding: '10px',
                      borderBottom: '1px solid #ccc',
                      backgroundColor: selectedPaciente === paciente.id ? 'lightgreen' : 'transparent', // Cambia de color si es seleccionado
                    }}
                  >
                    <strong>{`${paciente.nombre} ${paciente.apellido}`}</strong> - {paciente.telefono}
                  </div>
                ))}
              </div>
            </Form.Group>
            {/* Mostrar número de teléfono y correo si hay un paciente seleccionado */}
            {selectedPaciente && (
              <div>
                <p><strong>Teléfono:</strong> {pacienteInfo.telefono}</p>
                <p><strong>Email:</strong> {pacienteInfo.email}</p>
              </div>
            )}
          </Col>
          <Col md={6}>
            <Form.Group>
              <Form.Label style={{ color: '#b4c8dc' }}>Odontólogo</Form.Label>
              <Form.Control as="select" value={selectedOdontologo} onChange={(e) => setSelectedOdontologo(e.target.value)}>
                <option value="">Seleccione un odontólogo...</option>
                {odontologos.map((odontologo) => (
                  <option key={odontologo.id} value={odontologo.id}>
                    {`${odontologo.nombre} ${odontologo.apellido}`}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>
          </Col>
        </Row>
        <Row className="mb-3">
          <Col md={6}>
            <Form.Group>
            <Form.Label style={{ color: '#b4c8dc' }}>Tipo de Cita</Form.Label>
              <Form.Control as="select" value={tipoCita} onChange={(e) => setTipoCita(e.target.value)}>
                <option value="">Seleccione el tipo de cita...</option>
                <option value="Consulta">Consulta</option>
                <option value="Limpieza">Limpieza</option>
                <option value="Ortodoncia">Ortodoncia</option>
              </Form.Control>
            </Form.Group>
          </Col>
        </Row>
        <Row className="mb-3">
          <Col md={6}>
            <Form.Group>
              <Form.Label style={{ color: '#b4c8dc' }}>Fecha de Cita</Form.Label>
              <DatePicker
                selected={fechaCita}
                onChange={(date) => setFechaCita(date)}
                className="form-control"
                dateFormat="dd/MM/yyyy"
              />
            </Form.Group>
          </Col>
          <Col md={3}>
            <Form.Group>
              <Form.Label style={{ color: '#b4c8dc' }}>Hora de Inicio</Form.Label>
              <Form.Control
                as="select"
                value={horaInicio}
                onChange={(e) => setHoraInicio(e.target.value)}
              >
                <option value="">Seleccione la hora...</option>
                {generarHorasIntervalo().map((hora) => (
                  <option key={hora} value={hora}>{hora}</option>
                ))}
              </Form.Control>
            </Form.Group>
          </Col>
          <Col md={3}>
            <Form.Group>
              <Form.Label style={{ color: '#b4c8dc' }}>Hora de Fin</Form.Label>
              <Form.Control
                as="select"
                value={horaFin}
                onChange={(e) => setHoraFin(e.target.value)}
              >
                <option value="">Seleccione la hora...</option>
                {generarHorasIntervalo().map((hora) => (
                  <option key={hora} value={hora}>{hora}</option>
                ))}
              </Form.Control>
            </Form.Group>
          </Col>
        </Row>

        {/* Mostrar mensaje de error si hay un traslape */}
        {errorTraslape && (
          <Alert variant="danger" className="mt-3">
            Ya existe una cita para este odontólogo en el horario seleccionado. Por favor, elige otro horario.
          </Alert>
        )}

        <Button
          variant="primary"
          type="submit"
          className={`mt-3 ${loading || success ? 'btn-loading' : ''}`}
          style={{ backgroundColor: '#b4c8dc', borderColor: '#b4c8dc' }}
          disabled={loading}
        >
          {loading ? <Spinner animation="border" size="sm" /> : 'Agendar Cita'}
        </Button>

        {success && <div className="success-message mt-3">¡Cita agendada con éxito!</div>}
      </Form>

      <CitasAgendadas />
    </Container>
  );
};

export default CitasPaciente;

import React, { useEffect, useState } from 'react';
import { Table, Button, Form, Alert } from 'react-bootstrap';
import axios from 'axios';

const ListaEmpleados = () => {
  const [empleados, setEmpleados] = useState([]);
  const [cargos, setCargos] = useState([]);
  const [editEmpleadoId, setEditEmpleadoId] = useState(null);
  const [editedEmpleado, setEditedEmpleado] = useState({});
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');

  useEffect(() => {
    const obtenerEmpleados = async () => {
      try {
        const response = await axios.get('http://localhost:8000/empleados/');
        setEmpleados(response.data);
      } catch (error) {
        setErrorMessage('Error al obtener la lista de empleados');
       
        setTimeout(() => setErrorMessage(''), 3000);
      }
    };

    const obtenerCargos = async () => {
      try {
        const response = await axios.get('http://localhost:8000/cargos/');
        setCargos(response.data);
      } catch (error) {
        setErrorMessage('Error al obtener los cargos');
        setTimeout(() => setErrorMessage(''), 3000);
      }
    };

    obtenerEmpleados();
    obtenerCargos();
  }, []);

  const handleModificar = (empleado) => {
    setEditEmpleadoId(empleado.id);
    setEditedEmpleado({
      ...empleado,
      contrasena: '', // Contraseña vacía inicialmente
    });
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setEditedEmpleado({ ...editedEmpleado, [name]: value });
  };

  const handleGuardar = async (id) => {
    try {
      const empleadoData = { ...editedEmpleado };

      // Si no se cambió la contraseña, eliminarla de los datos
      if (!empleadoData.contrasena) {
        delete empleadoData.contrasena;
      }

      console.log('Datos que se envían:', empleadoData);

      const response = await axios.put(`http://localhost:8000/empleados/${id}/editar/`, empleadoData);

      const updatedEmpleados = empleados.map((empleado) => (empleado.id === id ? response.data : empleado));
      setEmpleados(updatedEmpleados);
      setEditEmpleadoId(null);
      setSuccessMessage('Empleado actualizado con éxito');
 
      setTimeout(() => setSuccessMessage(''), 2000);
    } catch (error) {
      console.log('Error al actualizar el empleado:', error.response.data);
      setErrorMessage('Error al actualizar el empleado');
      // Desaparecer mensaje de error después de 5 segundos
      setTimeout(() => setErrorMessage(''), 2000);
    }
  };

  const handleCancelar = () => {
    setEditEmpleadoId(null);
    setEditedEmpleado({});
  };

  const handleEliminar = async (id) => {
    try {
      await axios.delete(`http://localhost:8000/empleados/${id}/eliminar/`);
      setEmpleados(empleados.filter((empleado) => empleado.id !== id));
      setSuccessMessage('Empleado eliminado con éxito');
      setTimeout(() => setSuccessMessage(''), 2000); // Desaparecer mensaje de éxito después de 5 segundos
    } catch (error) {
      setErrorMessage('Error al eliminar el empleado');
      setTimeout(() => setErrorMessage(''), 2000); // Desaparecer mensaje de error después de 5 segundos
    }
  };

  return (
    <div className="table-container p-4" style={{ backgroundColor: '#f0f0f0', borderRadius: '8px' }}>
      <h3 style={{ color: '#c8dcf0' }}>Lista de Empleados</h3>
      {errorMessage && <Alert variant="danger">{errorMessage}</Alert>}
      {successMessage && <Alert variant="success">{successMessage}</Alert>}
      <Table responsive hover>
        <thead style={{ backgroundColor: '#c8dcf0' }}>
          <tr>
            <th>Nombre</th>
            <th>Apellido</th>
            <th>Correo Electrónico</th>
            <th>Teléfono</th>
            <th>Rol o Cargo</th>
            <th>Nombre de Usuario</th>
            <th>Contraseña</th>
            <th>Acciones</th>
          </tr>
        </thead>
        <tbody>
          {empleados.map((empleado) => (
            <tr key={empleado.id}>
              {editEmpleadoId === empleado.id ? (
                <>
                  <td><Form.Control type="text" name="nombre" value={editedEmpleado.nombre} onChange={handleInputChange} /></td>
                  <td><Form.Control type="text" name="apellido" value={editedEmpleado.apellido} onChange={handleInputChange} /></td>
                  <td><Form.Control type="email" name="correo" value={editedEmpleado.correo} onChange={handleInputChange} /></td>
                  <td><Form.Control type="tel" name="telefono" value={editedEmpleado.telefono} onChange={handleInputChange} /></td>
                  <td>
                    <Form.Control
                      as="select"
                      name="rol"
                      value={editedEmpleado.rol}
                      onChange={handleInputChange}
                    >
                      {cargos.map((cargo) => (
                        <option key={cargo.id} value={cargo.nombre}>
                          {cargo.nombre}
                        </option>
                      ))}
                    </Form.Control>
                  </td>
                  <td><Form.Control type="text" name="usuario" value={editedEmpleado.usuario} onChange={handleInputChange} /></td>
                  <td>
                    <Form.Control
                      type="password"
                      name="contrasena"
                      value={editedEmpleado.contrasena}
                      onChange={handleInputChange}
                      placeholder="••••••••"
                    />
                  </td>
                  <td>
                    <Button variant="success" className="me-2" onClick={() => handleGuardar(empleado.id)}>
                      Guardar
                    </Button>
                    <Button variant="secondary" onClick={handleCancelar}>
                      Cerrar
                    </Button>
                  </td>
                </>
              ) : (
                <>
                  <td>{empleado.nombre}</td>
                  <td>{empleado.apellido}</td>
                  <td>{empleado.correo}</td>
                  <td>{empleado.telefono}</td>
                  <td>{empleado.rol}</td>
                  <td>{empleado.usuario}</td>
                  <td>••••••••</td> {/* Contraseña oculta */}
                  <td>
                    <Button variant="warning" className="me-2" onClick={() => handleModificar(empleado)}>
                      Modificar
                    </Button>
                    <Button variant="danger" onClick={() => handleEliminar(empleado.id)}>
                      Eliminar
                    </Button>
                  </td>
                </>
              )}
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
  );
};

export default ListaEmpleados;

import React, { useState } from 'react';
import axios from 'axios';
import { Container, Form, Row, Col, Button } from 'react-bootstrap'; // Asegúrate de importar correctamente React-Bootstrap
import '../RegistrarInfo.css';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const RegistroPaciente = () => {
  const [formData, setFormData] = useState({
    nombre: '',
    apellido: '',
    telefono: '',
    email: '',
    direccion: '',
    fecha_nacimiento: '',
    genero: 'M',
  });

  const [errores, setErrores] = useState({}); // Añadir manejo de errores

  const validarTelefono = (telefono) => {
    const regex = /^[2345679]\d{7}$/;
    return regex.test(telefono);
  };

  const limpiarFormulario = () => {
    setFormData({
      nombre: '',
      apellido: '',
      telefono: '',
      email: '',
      direccion: '',
      fecha_nacimiento: '',
      genero: 'M',
    });
  };

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (!validarTelefono(formData.telefono)) {
      toast.error('Número de Teléfono Incorrecto.');
      setErrores({ telefono: 'Número de teléfono incorrecto' });
      return;
    }

    axios.post('http://localhost:8000/api/registrar-paciente/', formData)
      .then((response) => {
        toast.success('Paciente registrado con éxito');
        limpiarFormulario();
        setErrores({}); // Limpiar los errores después del éxito
      })
      .catch((error) => {
        if (error.response && error.response.data) {
          if (error.response.data.telefono) {
            toast.error(`Error: ${error.response.data.telefono}`);
            setErrores({ telefono: error.response.data.telefono });
          } else if (error.response.data.nombre) {
            toast.error(`Error: ${error.response.data.nombre}`);
          } else if (error.response.data.apellido) {
            toast.error(`Error: ${error.response.data.apellido}`);
          } else {
            toast.error('Error al registrar el paciente');
          }
        }
      });
  };

  return (
    <Container className="registro-container">
      <ToastContainer autoClose={3000} />
      <Form onSubmit={handleSubmit} className="form-registro shadow p-4 rounded">
        <h2 className="text-center mb-4">Registrar Paciente</h2>
        <Row>
          <Col md={6}>
            <Form.Group controlId="nombre">
              <Form.Label>Nombre</Form.Label>
              <Form.Control
                type="text"
                name="nombre"
                placeholder="Ingrese el nombre"
                value={formData.nombre}
                onChange={handleChange}
                required
                className="input-text"
              />
            </Form.Group>
          </Col>
          <Col md={6}>
            <Form.Group controlId="apellido">
              <Form.Label>Apellido</Form.Label>
              <Form.Control
                type="text"
                name="apellido"
                placeholder="Ingrese el apellido"
                value={formData.apellido}
                onChange={handleChange}
                required
                className="input-text"
              />
            </Form.Group>
          </Col>
        </Row>

        <Row>
          <Col md={6}>
            <Form.Group controlId="telefono">
              <Form.Label>Teléfono</Form.Label>
              <Form.Control
                type="tel"
                name="telefono"
                placeholder="Ingrese el teléfono"
                value={formData.telefono}
                onChange={handleChange}
                required
                className={`input-text ${errores.telefono ? 'is-invalid' : ''}`}
              />
              {errores.telefono && <div className="invalid-feedback">{errores.telefono}</div>}
            </Form.Group>
          </Col>
          <Col md={6}>
            <Form.Group controlId="email">
              <Form.Label>Correo Electrónico</Form.Label>
              <Form.Control
                type="email"
                name="email"
                placeholder="Ingrese el correo"
                value={formData.email}
                onChange={handleChange}
                required
                className="input-text"
              />
            </Form.Group>
          </Col>
        </Row>

        <Row>
          <Col md={6}>
            <Form.Group controlId="direccion">
              <Form.Label>Dirección</Form.Label>
              <Form.Control
                type="text"
                name="direccion"
                placeholder="Ingrese la dirección"
                value={formData.direccion}
                onChange={handleChange}
                required
                className="input-text"
              />
            </Form.Group>
          </Col>
          <Col md={6}>
            <Form.Group controlId="fecha_nacimiento">
              <Form.Label>Fecha de Nacimiento</Form.Label>
              <Form.Control
                type="date"
                name="fecha_nacimiento"
                value={formData.fecha_nacimiento}
                onChange={handleChange}
                required
                className="input-text"
              />
            </Form.Group>
          </Col>
        </Row>

        <Row>
          <Col md={6}>
            <Form.Group controlId="genero">
              <Form.Label>Género</Form.Label>
              <Form.Control
                as="select"
                name="genero"
                value={formData.genero}
                onChange={handleChange}
                required
                className="input-text"
              >
                <option value="">Seleccione el género</option>
                <option value="M">Masculino</option>
                <option value="F">Femenino</option>
              </Form.Control>
            </Form.Group>
          </Col>
        </Row>

        <Button type="submit" className="btn-submit mt-4 w-100">
          Registrar Paciente
        </Button>
      </Form>
    </Container>
  );
};

export default RegistroPaciente;

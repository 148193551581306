import React, { useState } from 'react';
import './Login.css';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

const Login = () => {
  const [emailOrUsername, setEmailOrUsername] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();

  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post('http://localhost:8000/api/login/', {
        emailOrUsername,
        password,
      });

      if (response.status === 200) {
        const { nombre, apellido, is_dentista } = response.data;
        localStorage.setItem('isLoggedIn', true); // Guardar la sesión
        localStorage.setItem('nombre', nombre);
        localStorage.setItem('apellido', apellido);
        localStorage.setItem('is_dentista', is_dentista);

        navigate(`/dashboard/${nombre}-${apellido}`); // Redirigir al dashboard
      }
    } catch (err) {
      setError('Error en el inicio de sesión, por favor verifique sus credenciales.');
      console.error('Error en el inicio de sesión:', err);
    }
  };

  const handleForgotPassword = () => {
    console.log('Redirigiendo a la página de recuperación de contraseña');
  };

  return (
    <div className="min-h-screen flex flex-col justify-center items-center bg-gray-100">
      <div className="bg-white shadow-lg rounded-lg p-8 md:w-1/3 w-11/12">
        <h2 className="text-2xl font-bold text-gray-800 mb-6 text-center">Iniciar Sesión</h2>
        
        {error && <p className="text-red-500 mb-4">{error}</p>}

        <form onSubmit={handleLogin}>
          <div className="mb-4">
            <label htmlFor="emailOrUsername" className="block text-gray-600 mb-2">Usuario o Correo Electrónico</label>
            <input
              type="text"
              id="emailOrUsername"
              className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
              value={emailOrUsername}
              onChange={(e) => setEmailOrUsername(e.target.value)}
              placeholder="Ingrese su usuario o correo electrónico"
              required
            />
          </div>

          <div className="mb-6">
            <label htmlFor="password" className="block text-gray-600 mb-2">Contraseña</label>
            <input
              type="password"
              id="password"
              className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              placeholder="Ingrese su contraseña"
              required
            />
          </div>

          <button type="submit" className="w-full bg-blue-600 text-white py-2 rounded-md hover:bg-blue-700 transition duration-300">
            Iniciar Sesión
          </button>

          <div className="text-center mt-4">
            <button type="button" onClick={handleForgotPassword} className="text-blue-600 hover:underline">
              Olvidé mi contraseña
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Login;

import React, { useState } from 'react';
import { Form, Button, Alert } from 'react-bootstrap';
import axios from 'axios';

const RegistrarEmpleado = () => {
  const [formData, setFormData] = useState({
    nombre: '',
    apellido: '',
    correo: '',
    telefono: '',
    rol: '',
    usuario: '',
    contrasena: '',
    confirmarContrasena: '',
  });

  const [roles, setRoles] = useState([]);
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  const validarTelefonoGuatemala = (telefono) => {
    const regex = /^[2345679]\d{7}$/;
    return regex.test(telefono);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();


    if (!validarTelefonoGuatemala(formData.telefono)) {
        setErrorMessage('El número de teléfono debe ser válido para Guatemala (8 dígitos, comenzando con 2, 3, 4, 5, 6, 7 o 9)');
        return;
      }

    // Validar que las contraseñas coincidan
    if (formData.contrasena !== formData.confirmarContrasena) {
      setErrorMessage('Las contraseñas no coinciden');
      return;
    }

    try {
      const response = await axios.post('http://localhost:8000/empleados/registrar/', formData);
      setSuccessMessage('Empleado registrado con éxito');
      setErrorMessage('');
      setFormData({
        nombre: '',
        apellido: '',
        correo: '',
        telefono: '',
        rol: '',
        usuario: '',
        contrasena: '',
        confirmarContrasena: '',
      });
    } catch (error) {
      setErrorMessage('Error al registrar el empleado');
      setSuccessMessage('');
    }
  };

  // Obtener los roles/cargos desde el backend cuando se carga el componente
  React.useEffect(() => {
    const fetchRoles = async () => {
      try {
        const response = await axios.get('http://localhost:8000/cargos/');
        setRoles(response.data);
      } catch (error) {
        console.error('Error al obtener los roles/cargos:', error);
      }
    };
    fetchRoles();
  }, []);


  return (
    <div className="form-container p-4" style={{ backgroundColor: '#f0f0f0', borderRadius: '8px' }}>
      <h3 style={{ color: '#c8dcf0' }}>Registrar Empleado</h3>
      {successMessage && <Alert variant="success">{successMessage}</Alert>}
      {errorMessage && <Alert variant="danger">{errorMessage}</Alert>}
      <Form onSubmit={handleSubmit}>
        <Form.Group controlId="nombre">
          <Form.Label style={{ color: '#b4c8dc' }}>Nombre</Form.Label>
          <Form.Control
            type="text"
            name="nombre"
            value={formData.nombre}
            onChange={handleInputChange}
            placeholder="Ingrese el nombre"
            required
          />
        </Form.Group>
        <Form.Group controlId="apellido">
          <Form.Label style={{ color: '#b4c8dc' }}>Apellido</Form.Label>
          <Form.Control
            type="text"
            name="apellido"
            value={formData.apellido}
            onChange={handleInputChange}
            placeholder="Ingrese el apellido"
            required
          />
        </Form.Group>
        <Form.Group controlId="correo">
          <Form.Label style={{ color: '#b4c8dc' }}>Correo Electrónico</Form.Label>
          <Form.Control
            type="email"
            name="correo"
            value={formData.correo}
            onChange={handleInputChange}
            placeholder="Ingrese el correo"
            required
          />
        </Form.Group>
        <Form.Group controlId="telefono">
          <Form.Label style={{ color: '#b4c8dc' }}>Teléfono</Form.Label>
          <Form.Control
            type="tel"
            name="telefono"
            value={formData.telefono}
            onChange={handleInputChange}
            placeholder="Ingrese el teléfono"
            required
          />
        </Form.Group>
     
        <Form.Group controlId="rol">
          <Form.Label style={{ color: '#b4c8dc' }}>Rol o Cargo</Form.Label>
          <Form.Control
            as="select"
            name="rol"
            value={formData.rol}
            onChange={handleInputChange}
            required
          >
            <option value="">Seleccione un rol...</option>
            {roles.map((rol) => (
              <option key={rol.id} value={rol.nombre}>
                {rol.nombre}
              </option>
            ))}
          </Form.Control>
        </Form.Group>




        <Form.Group controlId="usuario">
          <Form.Label style={{ color: '#b4c8dc' }}>Nombre de Usuario</Form.Label>
          <Form.Control
            type="text"
            name="usuario"
            value={formData.usuario}
            onChange={handleInputChange}
            placeholder="Ingrese el nombre de usuario"
            required
          />
        </Form.Group>
        <Form.Group controlId="contrasena">
          <Form.Label style={{ color: '#b4c8dc' }}>Contraseña</Form.Label>
          <Form.Control
            type="password"
            name="contrasena"
            value={formData.contrasena}
            onChange={handleInputChange}
            placeholder="Ingrese la contraseña"
            required
          />
        </Form.Group>
        <Form.Group controlId="confirmarContrasena">
          <Form.Label style={{ color: '#b4c8dc' }}>Confirmar Contraseña</Form.Label>
          <Form.Control
            type="password"
            name="confirmarContrasena"
            value={formData.confirmarContrasena}
            onChange={handleInputChange}
            placeholder="Confirme la contraseña"
            required
          />
        </Form.Group>
        <Button type="submit" style={{ backgroundColor: '#b4c8dc', borderColor: '#b4c8dc' }}>
          Registrar
        </Button>
      </Form>
    </div>
  );
};

export default RegistrarEmpleado;

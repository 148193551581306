import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Form } from 'react-bootstrap';
import axios from 'axios';
import './SaldoPaciente.css';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ComponenteResumenSaldos from './ComponenteResumenSaldos';
import ComponenteDetallePagos from './ComponenteDetallePagos';

const SaldoPaciente = () => {
  const [pacientes, setPacientes] = useState([]);
  const [selectedPaciente, setSelectedPaciente] = useState('');
  const [saldoData, setSaldoData] = useState([]);
  const [detallePagos, setDetallePagos] = useState([]);
  const [saldoTotal, setSaldoTotal] = useState(0);
  const [pacienteNombre, setPacienteNombre] = useState('');
  const [pacienteApellido, setPacienteApellido] = useState('');
  const [tratamientos, setTratamientos] = useState([]);
  const [selectedTratamiento, setSelectedTratamiento] = useState('');
  const [editPagoId, setEditPagoId] = useState(null);
  const [editAbono, setEditAbono] = useState('');
  const [editTratamiento, setEditTratamiento] = useState('');

  useEffect(() => {
    axios.get('http://localhost:8000/api/obtener-pacientes/')
      .then(response => {
        setPacientes(response.data);
      })
      .catch(error => {
        console.error('Error al obtener la lista de pacientes:', error);
      });
  }, []);

  // Función para obtener el saldo actualizado después de hacer modificaciones
  const fetchSaldoPaciente = () => {
    if (selectedPaciente) {
      axios.get(`http://localhost:8000/api/pacientes/${selectedPaciente}/saldo/`)
        .then(response => {
          setSaldoData(response.data.saldos);

          const paciente = pacientes.find(p => p.id === parseInt(selectedPaciente));
          if (paciente) {
            setPacienteNombre(paciente.nombre);
            setPacienteApellido(paciente.apellido);
          }

          const tratamientosMapeados = response.data.saldos.map(item => ({
            id: item.tratamiento_id || 'undefined-id',
            tratamiento_nombre: item.tratamiento_nombre || 'Tratamiento sin nombre',
          }));

          const tratamientosUnicos = tratamientosMapeados.filter(
            (tratamiento, index, self) =>
              index === self.findIndex(t => t.tratamiento_nombre === tratamiento.tratamiento_nombre)
          );

          setTratamientos(tratamientosUnicos);
        })
        .catch(error => {
          console.error('Error al obtener el saldo del paciente:', error);
          toast.error('Error al obtener el saldo del paciente.');
        });
    }
  };

  useEffect(() => {
    if (selectedPaciente) {
      axios.get(`http://localhost:8000/api/pacientes/${selectedPaciente}/pagos/`)
        .then(response => {
          setDetallePagos(response.data.pagos);
        })
        .catch(error => {
          console.error('Error al obtener los pagos del paciente:', error);
          toast.error('Error al obtener los pagos del paciente.');
        });
    }
  }, [selectedPaciente]);

  useEffect(() => {
    if (selectedPaciente) {
      fetchSaldoPaciente();
    }
  }, [selectedPaciente, pacientes]);

  useEffect(() => {
    if (saldoData.length > 0) {
      const total = saldoData.reduce((acc, item) => acc + item.saldo, 0);
      setSaldoTotal(total);
    }
  }, [saldoData]);

  const handlePacienteChange = (e) => {
    setSelectedPaciente(e.target.value);
    setSelectedTratamiento('');
  };

  const handleModificar = (pago) => {
    setEditPagoId(pago.id);
    setEditAbono(pago.monto);
    setEditTratamiento(pago.tratamiento_id);
  };

  const handleGuardar = async (pagoId) => {
    try {
      // Enviar los datos editados (tratamiento y monto) al backend
      const response = await axios.put(`http://localhost:8000/api/pagos/${pagoId}/modificar/`, {
        tratamiento_id: editTratamiento, // ID del tratamiento actualizado
        monto: editAbono,                // Monto actualizado
      });
  
      // Verificar la respuesta del backend
      if (response.status === 200) {
        toast.success('Pago modificado con éxito.');
  
        // Actualizar el estado con los pagos modificados
        const pagosActualizados = detallePagos.map(pago =>
          pago.id === pagoId ? { ...pago, tratamiento_id: editTratamiento, monto: editAbono } : pago
        );
        setDetallePagos(pagosActualizados);
  
        // Limpiar el modo de edición
        setEditPagoId(null);
  
        // Refrescar los saldos después de guardar la modificación
        fetchSaldoPaciente();
      } else {
        toast.error('Error al modificar el pago.');
      }
    } catch (error) {
      console.error('Error al modificar el pago:', error);
      toast.error('Error al guardar los cambios.');
    }
  };
  

  const handleCerrar = () => {
    setEditPagoId(null);
  };

  const handleEliminar = (pagoId) => {
    axios.delete(`http://localhost:8000/api/pagos/${pagoId}/eliminar/`)
      .then(() => {
        toast.success('Pago eliminado con éxito.');
        setDetallePagos(detallePagos.filter(pago => pago.id !== pagoId));
        fetchSaldoPaciente(); // Refrescar los datos de saldo después de eliminar el pago
      })
      .catch(error => {
        console.error('Error al eliminar el pago:', error);
        toast.error('Error al eliminar el pago.');
      });
  };
  

  return (
    <Container fluid className="saldo-paciente-container">
      <h3 className="text-center mb-4">Saldo de Pacientes</h3>

      <Form.Group as={Row} controlId="pacienteSelect">
        <Form.Label column sm={2}>Seleccionar Paciente:</Form.Label>
        <Col sm={10}>
          <Form.Control as="select" value={selectedPaciente} onChange={handlePacienteChange}>
            <option value="">Seleccione un paciente...</option>
            {pacientes.map(paciente => (
              <option key={paciente.id} value={paciente.id}>
                {paciente.nombre} {paciente.apellido}
              </option>
            ))}
          </Form.Control>
        </Col>
      </Form.Group>

      {selectedPaciente && (
        <div className="saldo-total mt-4">
          <h4>Saldo Total: Q {saldoTotal.toFixed(2)}</h4>
        </div>
      )}

      {selectedPaciente && (
        <ComponenteResumenSaldos
          saldoData={saldoData}
          pacienteNombre={pacienteNombre}
          pacienteApellido={pacienteApellido}
        />
      )}

      {selectedPaciente && (
        <ComponenteDetallePagos
          detallePagos={detallePagos}
          tratamientos={tratamientos}
          selectedTratamiento={selectedTratamiento}
          setSelectedTratamiento={setSelectedTratamiento}
          pacienteNombre={pacienteNombre}
          pacienteApellido={pacienteApellido}
          handleModificar={handleModificar}
          handleGuardar={handleGuardar}
          handleCerrar={handleCerrar}
          handleEliminar={handleEliminar}
          editPagoId={editPagoId}
          setEditPagoId={setEditPagoId}
          editAbono={editAbono}
          setEditAbono={setEditAbono}
          editTratamiento={editTratamiento}
          setEditTratamiento={setEditTratamiento}
        />
      )}

      <ToastContainer />
    </Container>
  );
};

export default SaldoPaciente;

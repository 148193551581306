import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import CarouselComponent from './Carrusel';
import PlanesPago from './PlanesPago';
import Footer from './Footer';

const Home = () => {
  const location = useLocation();
  
  useEffect(() => {
    if (location.state?.scrollTo === "inicio") {
      const planesSection = document.getElementById("inicio");
      if (planesSection) {
        planesSection.scrollIntoView({ behavior: "smooth" });
      }
    }
  }, [location]);
  useEffect(() => {
    if (location.state?.scrollTo === "planes") {
      const planesSection = document.getElementById("planes");
      if (planesSection) {
        planesSection.scrollIntoView({ behavior: "smooth" });
      }
    }
  }, [location]);
  useEffect(() => {
    if (location.state?.scrollTo === "footer") {
      const planesSection = document.getElementById("footer");
      if (planesSection) {
        planesSection.scrollIntoView({ behavior: "smooth" });
      }
    }
  }, [location]);
  
  return (
    <div className="home">
      {/* Inicio Section */}
      <div id="inicio">
        <CarouselComponent />
      </div>

      {/* Planes Section */}
      <div id="planes">
        <PlanesPago />
      </div>

      {/* Contacto Section */}
      <div id="footer">
        {/* Contenido de la sección de contacto */}
      <Footer />
      </div>

      {/* Footer */}
    </div>
  );
};

export default Home;

import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Navbar, Nav, Container, Button } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import './Navbar.css'; // Archivo CSS para personalización adicional

import logo from '../assets/logo.jpg'; // Asegúrate de tener el logo en esta ruta

const NavbarComponent = () => {
  const navigate = useNavigate();

  // Función para ir a "Home" y desplazarse a la sección de planes
  
  
  const handleNavigateToCarrusel = () => {
    navigate("/", { state: { scrollTo: "inicio" } });
  };
  const handleNavigateToPlans = () => {
    navigate("/", { state: { scrollTo: "planes" } });
  };

  const handleNavigateToContacto = () => {
    navigate("/", { state: { scrollTo: "footer" } });
  };
  
  
  return (
    <Navbar expand="lg" className="navbar-custom sticky-top" collapseOnSelect>
      <Container>
        {/* Logo */}
        <Navbar.Brand href="/" className="d-flex align-items-center">
          <img
            src={logo} // Logo
            alt="Clinica Dental Logo"
            className="navbar-logo"
          />
          <span className="ms-2 fw-bold">Odontología Especializada</span>
        </Navbar.Brand>

        {/* Toggle Button for Mobile */}
        <Navbar.Toggle aria-controls="navbar-nav" />

        {/* Links */}
        <Navbar.Collapse id="navbar-nav">
          <Nav className="ms-auto d-flex align-items-center">
            <Nav.Link onClick={handleNavigateToCarrusel} className="nav-link-custom">Inicio</Nav.Link>
            <Nav.Link onClick={handleNavigateToPlans} className="nav-link-custom">Planes</Nav.Link>
            <Nav.Link onClick={handleNavigateToContacto} to="/" className="nav-link-custom">Contacto</Nav.Link>
            <Nav.Link as={Link} to="/" className="nav-link-custom">Agendar Cita</Nav.Link>

            {/* Botón de Iniciar Sesión */}
            <Button as={Link} to="/login" variant="primary" className="ms-3">
              Iniciar Sesión
            </Button>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default NavbarComponent;

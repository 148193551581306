import React from 'react';
import { FaWaze, FaFacebookF, FaInstagram, FaWhatsapp, FaMapMarkedAlt } from 'react-icons/fa';

const Footer = () => {
  return (
    <footer className="bg-gray-800 text-white py-10 px-5">
      <div className="container mx-auto grid grid-cols-1 md:grid-cols-3 gap-8">

        {/* Información de contacto */}
        <div className="md:w-full">
          <h3 className="text-lg text-white font-bold mb-4">Información de Contacto</h3>
          <p>Dirección: Calzada Sta. Lucía Sur No. 7 "Casa de Salud Sta. Lucía" Clínica No. 2 y No. 8, Antigua Guatemala, Sacatepéquez, Guatemala.</p>
          <p>Teléfono: +502 7832 6914</p>
          <p>Horario de Atención: Lunes a Viernes, 8:00 AM - 5:00 PM</p>
        </div>

        {/* Redes sociales */}
        <div className="md:w-full">
          <h3 className="text-lg text-white font-bold mb-4">Redes Sociales</h3>
          <ul className="flex space-x-4">
            <li>
              <a href="https://facebook.com" target="_blank" rel="noreferrer" className="hover:text-blue-500">
                <FaFacebookF size={24} /> {/* Ícono de Facebook */}
              </a>
            </li>
            <li>
              <a href="https://instagram.com" target="_blank" rel="noreferrer" className="hover:text-pink-500">
                <FaInstagram size={24} /> {/* Ícono de Instagram */}
              </a>
            </li>
            <li>
              <a href="https://wa.me/50212345678" target="_blank" rel="noreferrer" className="hover:text-green-500">
                <FaWhatsapp size={24} /> {/* Ícono de WhatsApp */}
              </a>
            </li>
          </ul>
        </div>




        {/* Ubicación */}
        <div className="md:w-full">
          <h3 className="text-lg text-white font-bold mb-4">Ubicación</h3>
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3861.7574844449987!2d-90.74039492623706!3d14.555856278173271!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x85890e0db42b7d23%3A0x15cf082bb30a4602!2sCasa%20de%20Salud%20Santa%20Lucia!5e0!3m2!1ses!2sgt!4v1727402547769!5m2!1ses!2sgt"
            width="100%"
            height="150"
            allowFullScreen=""
            loading="lazy"
            title="Mapa de ubicación"
            className="border-0"
          ></iframe>
          <div className="mt-4 flex flex-col md:flex-row space-y-2 md:space-y-0 md:space-x-2">
            <a
              href="https://ul.waze.com/ul?ll=14.55784208%2C-90.73775768&navigate=yes&zoom=17&utm_campaign=default&utm_source=waze_website&utm_medium=lm_share_location"
              className="bg-blue-600 text-white px-4 py-2 rounded flex items-center justify-center w-full md:w-1/2"
              target="_blank"
              rel="noreferrer"
            >
              <FaWaze className="mr-2" /> {/* Ícono de Waze */}
              Waze
            </a>
            <a
              href="https://maps.app.goo.gl/mu2ttz614rsXihst8"
              className="bg-green-600 text-white px-4 py-2 rounded flex items-center justify-center w-full md:w-1/2"
              target="_blank"
              rel="noreferrer"
            >
              <FaMapMarkedAlt className="mr-2" /> {/* Ícono de Google Maps */}
              Google Maps
            </a>
          </div>
        </div>
      </div>
      <div className="text-center text-sm mt-8">
        © 2024 Orthodontic Center. Todos los derechos reservados.
      </div>
    </footer>
  );
};

export default Footer;

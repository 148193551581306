import React from 'react';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css'; // Estilos necesarios para el carrusel
import './Carrusel.css'; // Aquí puedes agregar estilos personalizados si es necesario


// Importa las imágenes
import dental1 from '../assets/images/dental1.jpg';
import dental2 from '../assets/images/dental2.jpg';
import dental3 from '../assets/images/dental3.jpg';


const CarouselComponent = () => {
    return (
        <Carousel 
            autoPlay 
            interval={5000} 
            infiniteLoop 
            showThumbs={false} 
            showStatus={false} 
            dynamicHeight={true}
            showArrows={false}
            className="carousel-container"
        >
            <div>
                <img src={dental1} alt="Slide 1" />
            </div>
            <div>
                <img src={dental2} alt="Slide 2" />
            </div>
            <div>
                <img src={dental3} alt="Slide 3" />
            </div>
        </Carousel>
    );
};

export default CarouselComponent;

import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Table, Form, Button } from 'react-bootstrap';
import axios from 'axios'; // Para las solicitudes HTTP
import './PagosPaciente.css'; // Estilos personalizados
import { toast, ToastContainer } from 'react-toastify'; // Importar ToastContainer y toast
import 'react-toastify/dist/ReactToastify.css';

const PagosPaciente = () => {
  const [pacientes, setPacientes] = useState([]);
  const [tratamientos, setTratamientos] = useState([]);
  const [selectedPaciente, setSelectedPaciente] = useState('');
  const [selectedTratamiento, setSelectedTratamiento] = useState('');
  const [pagos, setPagos] = useState([]);
  const [pacienteNombre, setPacienteNombre] = useState('');
  const [abonoFecha, setAbonoFecha] = useState(''); // Estado para la fecha del abono
  const [abonoMonto, setAbonoMonto] = useState('');

  // Obtener la fecha actual en formato YYYY-MM-DD
  useEffect(() => {
    const today = new Date().toISOString().split('T')[0];
    setAbonoFecha(today); // Establecer la fecha actual por defecto
  }, []);

  useEffect(() => {
    // Obtener lista de pacientes
    axios.get('http://localhost:8000/api/obtener-pacientes/')
      .then(response => {
        setPacientes(response.data);
      })
      .catch(error => {
        console.error('Error al obtener la lista de pacientes:', error);
      });
  }, []);

  useEffect(() => {
    if (selectedPaciente) {
      // Obtener tratamientos del paciente seleccionado
      axios.get(`http://localhost:8000/api/obtener-tratamientos-paciente/${selectedPaciente}/`)
        .then(response => {
          setTratamientos(response.data.tratamientos);
          // Buscar el nombre y apellido del paciente seleccionado
          const paciente = pacientes.find(p => p.id === parseInt(selectedPaciente));
          if (paciente) {
            setPacienteNombre(`${paciente.nombre} ${paciente.apellido}`);
          }
        })
        .catch(error => {
          console.error('Error al obtener los tratamientos:', error);
        });
    }
  }, [selectedPaciente, pacientes]);

  // Manejador de cambios para el filtro de paciente
  const handlePacienteChange = (e) => {
    setSelectedPaciente(e.target.value);
    setSelectedTratamiento(''); // Restablecer el tratamiento seleccionado al cambiar de paciente
  };

  // Manejador del botón Abonar
  const handleAbonar = () => {
    if (selectedPaciente && selectedTratamiento && abonoFecha && abonoMonto) {
      // Guardar el abono en la base de datos
      axios.post(`http://localhost:8000/api/pacientes/${selectedPaciente}/abonar/`, {
        tratamiento_id: selectedTratamiento,
        fecha: abonoFecha,
        monto: abonoMonto,
      })
      .then(response => {
        toast.success('Abono realizado con éxito.');
        setAbonoFecha(new Date().toISOString().split('T')[0]); // Restablecer la fecha actual después de abonar
        setAbonoMonto('');
        setSelectedTratamiento('');
        // Actualizar la lista de pagos
        setPagos([...pagos, { 
          tratamiento: tratamientos.find(t => t.id === parseInt(selectedTratamiento)).tipo_tratamiento, 
          fecha: abonoFecha, 
          monto: abonoMonto 
        }]);
      })
      .catch(error => {
        console.error('Error al realizar el abono:', error);
        toast.error('Error al realizar el abono.');
      });
    } else {
      toast.warn('Por favor, complete todos los campos para realizar el abono.');
    }
  };

  return (
    <Container fluid className="pagos-paciente-container" style={{ backgroundColor: '#f0f0f0', padding: '20px', borderRadius: '8px' }}>
      <h3 className="text-center mb-4" style={{ color: '#b4c8dc' }}>Pagos de Pacientes</h3>

      {/* Filtro de Pacientes */}
      <Form.Group as={Row} controlId="pacienteSelect">
        <Form.Label column sm={2} style={{ color: '#b4c8dc' }}>Seleccionar Paciente:</Form.Label>
        <Col sm={10}>
          <Form.Control as="select" value={selectedPaciente} onChange={handlePacienteChange} style={{ backgroundColor: '#ffffff' }}>
            <option value="">Seleccione un paciente...</option>
            {pacientes.map(paciente => (
              <option key={paciente.id} value={paciente.id}>
                {paciente.nombre} {paciente.apellido}
              </option>
            ))}
          </Form.Control>
        </Col>
      </Form.Group>

      {/* Tabla de Pagos */}
      <div className="pagos-tabla mt-4" style={{ backgroundColor: '#c8dcf0', padding: '20px', borderRadius: '8px' }}>
        <h4 className="mb-4" style={{ color: '#ffffff' }}>Abonar Pago</h4>
        <Table striped bordered hover responsive>
          <thead>
            <tr style={{ backgroundColor: '#b4c8dc' }}>
              <th>Nombre Paciente</th>
              <th>Tratamiento</th>
              <th>Fecha</th>
              <th>Monto</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>{pacienteNombre}</td>
              <td>
                <Form.Control 
                  as="select" 
                  value={selectedTratamiento} 
                  onChange={(e) => setSelectedTratamiento(e.target.value)} 
                  style={{ backgroundColor: '#ffffff' }}
                >
                  <option value="">Seleccione un tratamiento...</option>
                  {tratamientos.map(tratamiento => (
                    <option key={tratamiento.id} value={tratamiento.id}>
                      {tratamiento.tipo_tratamiento}
                    </option>
                  ))}
                </Form.Control>
              </td>
              <td>
                <Form.Control 
                  type="date" 
                  value={abonoFecha} 
                  onChange={(e) => setAbonoFecha(e.target.value)} 
                />
              </td>
              <td>
                <Form.Control 
                  type="number" 
                  placeholder="Q" 
                  value={abonoMonto} 
                  onChange={(e) => setAbonoMonto(e.target.value)} 
                />
              </td>
            </tr>
          </tbody>
        </Table>
      </div>

      {/* Botón Abonar */}
      <div className="abonar-boton mt-4 text-center">
        <Button 
          variant="primary" 
          onClick={handleAbonar} 
          style={{ backgroundColor: '#b4c8dc', borderColor: '#b4c8dc', padding: '10px 20px', borderRadius: '8px' }}
        >
          Abonar
        </Button>
      </div>

      <ToastContainer />
    </Container>
  );
};

export default PagosPaciente;

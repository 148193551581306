// ComponenteResumenSaldos.js
import React from 'react';
import { Table } from 'react-bootstrap';

const ComponenteResumenSaldos = ({ saldoData, pacienteNombre, pacienteApellido }) => {
  return (
    <div className="saldos-tabla mt-4" style={{ backgroundColor: '#c8dcf0', padding: '20px', borderRadius: '8px' }}>
      <h4 className="mb-4" style={{ color: '#ffffff' }}>Resumen de Saldos</h4>
      <Table striped bordered hover responsive>
        <thead>
          <tr style={{ backgroundColor: '#b4c8dc' }}>
            <th>Nombre Paciente</th>
            <th>Tratamientos</th>
            <th>Precio Tratamiento</th>

            <th>Abono</th>
            <th>Saldo</th>
          </tr>
        </thead>
        <tbody>
          {saldoData.length > 0 ? (
            saldoData.map((saldo, index) => (
              <tr key={index}>
                <td>{pacienteNombre} {pacienteApellido}</td>
                <td>{saldo.tratamiento_nombre}</td>
                <td>Q {saldo.precio_tratamiento}</td>

                <td>Q {saldo.abono}</td>
                <td>Q {saldo.saldo}</td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan="6" className="text-center">No hay datos de saldo para este paciente.</td>
            </tr>
          )}
        </tbody>
      </Table>
    </div>
  );
};

export default ComponenteResumenSaldos;

// src/components/PlanesPago.js
import React from 'react';
import { FaCheckCircle } from 'react-icons/fa';

const planes = [
  {
    title: "Plan Básico",
    cobertura: [
      "Limpieza Dental Profesional",
      "Examen Dental Completo",
      "Radiografías Básicas (1 vez al año)",
    ],
    duracion: "12 meses",
    costo: "Q300/mes",
    beneficios: [
      "10% de descuento en tratamientos adicionales (Rellenos, extracciones, etc.)",
      "Prioridad en la Agenda De Citas",
    ],
  },
  {
    title: "Plan Avanzado",
    cobertura: [
      "Todo lo que incluye el plan básico",
      "Blanqueamiento Dental",
      "Sellado Dental Preventivo",
      "Radiografías Avanzadas (Panorámicas)",
    ],
    duracion: "12 meses",
    costo: "Q500/mes",
    beneficios: [
      "15% de descuento en tratamientos adicionales",
      "Revisión ortodóntica anual gratis",
      "Atención prioritaria en emergencias dentales",
    ],
  },
  {
    title: "Plan Premium",
    cobertura: [
      "Todo lo incluido en el plan avanzado",
      "Ortodoncia (Brackets o alineadores transparentes)",
      "Implantes dentales (1 implante al año)",
      "Tratamientos de endodoncia sin costo adicional",
    ],
    duracion: "12 meses",
    costo: "Q1000/mes",
    beneficios: [
      "20% de descuento en cualquier tratamiento adicional",
      "Atención personalizada con un especialista",
      "Revisión semestral gratuita con escaneo digital 3D de la boca",
    ],
  },
];

const PlanesPago = () => {
  return (
    <div className="bg-gray-100 py-12">
      <h2 className="text-4xl font-bold text-center mb-8">Planes de Pago</h2>
      <div className="flex flex-wrap justify-center">
        {planes.map((plan, index) => (
          <div
            key={index}
            className="bg-white shadow-lg rounded-lg m-4 p-6 w-full sm:w-80 lg:w-96 text-center"
          >
            <h3 className="text-2xl font-semibold mb-4">{plan.title}</h3>
            <p className="text-gray-700 font-bold mb-4">{plan.costo}</p>
            <h4 className="text-lg font-semibold mb-2">Cobertura:</h4>
            <ul className="text-left mb-4">
              {plan.cobertura.map((item, i) => (
                <li key={i} className="flex items-center mb-2">
                  <FaCheckCircle className="text-green-500 mr-2" />
                  {item}
                </li>
              ))}
            </ul>
            <p className="font-semibold mb-4">Duración del plan: {plan.duracion}</p>
            <h4 className="text-lg font-semibold mb-2">Beneficios Adicionales:</h4>
            <ul className="text-left">
              {plan.beneficios.map((beneficio, i) => (
                <li key={i} className="flex items-center mb-2">
                  <FaCheckCircle className="text-green-500 mr-2" />
                  {beneficio}
                </li>
              ))}
            </ul>
          </div>
        ))}
      </div>
    </div>
  );
};

export default PlanesPago;

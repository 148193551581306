import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { Container, Row, Col, Card, Button, Form, Modal } from 'react-bootstrap';
import './FichaPaciente.css';

const FichaPaciente = ({ paciente }) => {
  const [isEditing, setIsEditing] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [updateMessage, setUpdateMessage] = useState('');
  const [editedPaciente, setEditedPaciente] = useState(paciente);
  const [dentistas, setDentistas] = useState([]); // Lista de dentistas registrados
  const [selectedDentista, setSelectedDentista] = useState(paciente.odontologo || null); // Odontólogo seleccionado (si ya está asociado)
  const [showDentistaModal, setShowDentistaModal] = useState(false); // Modal para seleccionar dentista
  const [tempSelectedDentista, setTempSelectedDentista] = useState(null); // Temporariamente para el odontólogo en el modal
  const navigate = useNavigate();

  useEffect(() => {
    // Obtener lista de dentistas registrados para el campo "Seleccionar Odontólogo"
    axios.get('http://localhost:8000/api/obtener-dentistas/')
      .then((response) => {
        setDentistas(response.data);
      })
      .catch((error) => {
        console.error('Error al obtener los dentistas:', error);
      });
  }, []);

  const handleEdit = () => {
    setIsEditing(true);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setEditedPaciente({
      ...editedPaciente,
      [name]: value
    });
  };

  const handleDentistaChange = (dentista) => {
    setTempSelectedDentista(dentista); // Almacena temporalmente el odontólogo seleccionado
  };

  const handleSaveDentista = () => {
    setSelectedDentista(tempSelectedDentista); // Guarda definitivamente el odontólogo seleccionado
    setShowDentistaModal(false); // Cierra el modal
  };

  const handleSaveChanges = () => {
    // Añadir el odontólogo seleccionado a los datos del paciente
    const updatedPaciente = {
      ...editedPaciente,
      odontologo_id: selectedDentista ? selectedDentista.id : null, // Añadir ID del odontólogo al objeto paciente
    };

    axios.put(`http://localhost:8000/api/actualizar-paciente/${paciente.id}/`, updatedPaciente)
      .then(() => {
        setUpdateMessage('Paciente actualizado con éxito.');
        setShowModal(true);
        setIsEditing(false);
      })
      .catch((error) => {
        setUpdateMessage('Error al actualizar el paciente.');
        setShowModal(true);
        console.error('Error al actualizar paciente:', error);
      });
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handleOpenDentistaModal = () => {
    setShowDentistaModal(true);
  };

  const handleCloseDentistaModal = () => {
    setShowDentistaModal(false);
  };

  return (
    <Container className="ficha-paciente-container py-5">
      {editedPaciente ? (
        <>
          <h2 className="text-center mb-4">Ficha del Paciente</h2>
          <Row className="mb-4">
            <Col md={6}>
              <Card className="shadow-sm">
                <Card.Header className="bg-primary text-white">Datos Personales del Paciente</Card.Header>
                <Card.Body>
                  <Form>
                    <Form.Group>
                      <Form.Label><strong>Nombre:</strong></Form.Label>
                      {isEditing ? (
                        <Form.Control
                          type="text"
                          name="nombre"
                          value={editedPaciente.nombre}
                          onChange={handleInputChange}
                        />
                      ) : (
                        <Card.Text>{editedPaciente.nombre}</Card.Text>
                      )}
                    </Form.Group>

                    <Form.Group>
                      <Form.Label><strong>Apellido:</strong></Form.Label>
                      {isEditing ? (
                        <Form.Control
                          type="text"
                          name="apellido"
                          value={editedPaciente.apellido}
                          onChange={handleInputChange}
                        />
                      ) : (
                        <Card.Text>{editedPaciente.apellido}</Card.Text>
                      )}
                    </Form.Group>

                    <Form.Group>
                      <Form.Label><strong>Teléfono:</strong></Form.Label>
                      {isEditing ? (
                        <Form.Control
                          type="text"
                          name="telefono"
                          value={editedPaciente.telefono}
                          onChange={handleInputChange}
                        />
                      ) : (
                        <Card.Text>{editedPaciente.telefono}</Card.Text>
                      )}
                    </Form.Group>

                    <Form.Group>
                      <Form.Label><strong>Correo Electrónico:</strong></Form.Label>
                      {isEditing ? (
                        <Form.Control
                          type="email"
                          name="email"
                          value={editedPaciente.email}
                          onChange={handleInputChange}
                        />
                      ) : (
                        <Card.Text>{editedPaciente.email}</Card.Text>
                      )}
                    </Form.Group>

                    <Form.Group>
                      <Form.Label><strong>Dirección:</strong></Form.Label>
                      {isEditing ? (
                        <Form.Control
                          type="text"
                          name="direccion"
                          value={editedPaciente.direccion}
                          onChange={handleInputChange}
                        />
                      ) : (
                        <Card.Text>{editedPaciente.direccion}</Card.Text>
                      )}
                    </Form.Group>

                    <Form.Group>
                      <Form.Label><strong>Fecha de Nacimiento:</strong></Form.Label>
                      {isEditing ? (
                        <Form.Control
                          type="date"
                          name="fecha_nacimiento"
                          value={editedPaciente.fecha_nacimiento}
                          onChange={handleInputChange}
                        />
                      ) : (
                        <Card.Text>{editedPaciente.fecha_nacimiento}</Card.Text>
                      )}
                    </Form.Group>

                    <Form.Group>
                      <Form.Label><strong>Género:</strong></Form.Label>
                      {isEditing ? (
                        <Form.Control
                          as="select"
                          name="genero"
                          value={editedPaciente.genero}
                          onChange={handleInputChange}
                        >
                          <option value="M">Masculino</option>
                          <option value="F">Femenino</option>
                          <option value="O">Otro</option>
                        </Form.Control>
                      ) : (
                        <Card.Text>{editedPaciente.genero}</Card.Text>
                      )}
                    </Form.Group>

                    <Form.Group>
                      <Form.Label><strong>Ocupación:</strong></Form.Label>
                      {isEditing ? (
                        <Form.Control
                          type="text"
                          name="ocupacion"
                          value={editedPaciente.ocupacion}
                          onChange={handleInputChange}
                        />
                      ) : (
                        <Card.Text>{editedPaciente.ocupacion || 'No especificada'}</Card.Text>
                      )}
                    </Form.Group>
                  </Form>
                </Card.Body>
              </Card>
            </Col>

            <Col md={6}>
              <Card className="shadow-sm">
                <Card.Header className="bg-success text-white">
                  Datos del Odontólogo
                </Card.Header>
                <Card.Body>
                  {selectedDentista ? (
                    <>
                      <Card.Text><strong>Nombre:</strong> {selectedDentista.nombre}</Card.Text>
                      <Card.Text><strong>Apellido:</strong> {selectedDentista.apellido}</Card.Text>
                      <Card.Text><strong>Especialidad:</strong> {selectedDentista.especializacion}</Card.Text>
                      <Card.Text><strong>Colegiado:</strong> {selectedDentista.colegiado}</Card.Text>
                      <Card.Text><strong>Correo Electrónico:</strong> {selectedDentista.correo}</Card.Text>
                      <Card.Text><strong>Teléfono:</strong> {selectedDentista.telefono}</Card.Text>
                    </>
                  ) : (
                    <p>No se ha seleccionado ningún odontólogo.</p>
                  )}
                  <Button variant="primary" onClick={handleOpenDentistaModal}>
                    Seleccionar Profesional
                  </Button>
                </Card.Body>
              </Card>
            </Col>
          </Row>

          <Row className="text-center">
            <Col>
              {isEditing && (
                <>
                  <Button variant="success" className="me-2" onClick={handleSaveChanges}>Guardar Cambios</Button>
                  <Button variant="secondary" onClick={() => setIsEditing(false)}>Cancelar</Button>
                </>
              )}
              {!isEditing && (
                <Button variant="primary" className="me-2" onClick={handleEdit}>Editar Paciente</Button>
              )}
              <Button variant="danger" onClick={() => navigate('/filtro-paciente')}>Volver</Button>
            </Col>
          </Row>

          {/* Modal para mostrar mensajes */}
          <Modal show={showModal} onHide={handleCloseModal} centered>
            <Modal.Header closeButton>
              <Modal.Title>Resultado de la Actualización</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              {updateMessage}
            </Modal.Body>
            <Modal.Footer>
              <Button variant="primary" onClick={handleCloseModal}>
                Cerrar
              </Button>
            </Modal.Footer>
          </Modal>

          {/* Modal para seleccionar odontólogo */}
          <Modal show={showDentistaModal} onHide={handleCloseDentistaModal} centered>
            <Modal.Header closeButton>
              <Modal.Title>Seleccionar Odontólogo</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Form.Group>
                <Form.Control as="select" onChange={(e) => handleDentistaChange(dentistas.find(d => d.id === parseInt(e.target.value)))}>
                  <option value="">Seleccione un odontólogo</option>
                  {dentistas.map((dentista) => (
                    <option key={dentista.id} value={dentista.id}>
                      {dentista.nombre} {dentista.apellido}
                    </option>
                  ))}
                </Form.Control>
              </Form.Group>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="success" onClick={handleSaveDentista}>
                Guardar
              </Button>
              <Button variant="secondary" onClick={handleCloseDentistaModal}>
                Cancelar
              </Button>
            </Modal.Footer>
          </Modal>
          
        </>
      ) : (
        <p>Cargando información del paciente...</p>
      )}
    </Container>
  );
};

export default FichaPaciente;

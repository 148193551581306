import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';
import { matchPath } from 'react-router-dom';
import NavbarComponent from './Home/Navbar'; // Asegúrate de que la ruta sea correcta
import Home from './Home/Home';
import Login from './Dashboard/Login';
import Dashboard from './Dashboard/Dashboard';
import RegistroPaciente from './Dashboard/Paciente/RegistroPaciente';
import FiltroPaciente from './Dashboard/Paciente/FiltroPaciente';
import FichaPaciente from './Dashboard/Paciente/FichaPaciente';
import RegistroDentista from './Dashboard/Doctores/RegistroDentista';


// Componente que decide si mostrar o no el Navbar basado en la ruta actual
const Layout = ({ children }) => {
  const location = useLocation();

  // Rutas donde no quieres mostrar el Navbar
  const hideNavbarPaths = [
    '/dashboard/*', // Asegúrate de que las rutas coincidan con el nuevo patrón dinámico
    '/registrar-paciente',
    '/filtro-paciente',
    '/ficha-paciente/:id',
    '/registrar-dentista'
  ];

  // Función que comprueba si alguna de las rutas coincide, incluyendo dinámicas
  const shouldShowNavbar = !hideNavbarPaths.some((path) => matchPath(path, location.pathname));

  return (
    <div>
      {shouldShowNavbar && <NavbarComponent />} {/* Mostrar Navbar solo si no estás en las rutas definidas */}
      {children}
    </div>
  );
};

const App = () => {
  return (
    <Router>
      <Layout>
        <Routes>
          <Route path="/" element={<Home />} /> {/* Ruta para la página principal */}

          <Route path="/login" element={<Login />} /> {/* Ruta para la página de inicio de sesión */}
          <Route path="/dashboard/:username" element={<Dashboard />} /> {/* Ruta para el Dashboard con nombre de usuario dinámico */}
          <Route path="/registrar-paciente" element={<RegistroPaciente />} /> {/* Ruta para Registro Paciente */}
          <Route path="/filtro-paciente" element={<FiltroPaciente />} /> {/* Ruta para Filtro Paciente */}
          <Route path="/ficha-paciente/:id" element={<FichaPaciente />} /> {/* Ruta para Ficha Paciente */}
          <Route path="/registrar-dentista" element={<RegistroDentista />} /> {/* Ruta para Ficha Paciente */}
          
        </Routes>
      </Layout>
    </Router>
  );
};

export default App;

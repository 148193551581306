import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Form, Button, Row, Col, Alert } from 'react-bootstrap';

const HistorialPaciente = ({ pacienteId }) => {
  const [historial, setHistorial] = useState({
    enfermedades_sistemicas: '',
    alergias: '',
    tratamiento_medico: '',
    motivo_consulta: '',
    tratamientos_previos: '',
    detalle_alergia: '',
    detalle_tratamiento: '',
    fecha_consulta: '',
  }); // Estado inicial predeterminado
  const [isEditing, setIsEditing] = useState(false); // Controla si estamos editando o no
  const [mensaje, setMensaje] = useState(''); // Para mostrar el resultado de la acción
  const [isLoading, setIsLoading] = useState(true); // Controla si los datos están cargando

  // Cargar historial al montar el componente
  useEffect(() => {
    axios
      .get(`http://localhost:8000/api/obtener-historial-paciente/${pacienteId}/`)
      .then((response) => {
        if (response.data.message) {
          // No hay historial, se puede crear uno
          setIsEditing(true);
        } else {
          // Si hay historial, mostrar los datos
          setHistorial(response.data[0] || historial); // Asumimos que hay un solo historial
          setIsEditing(false); // Mostrar en modo solo lectura por defecto
        }
        setIsLoading(false); // Terminar la carga
      })
      .catch((error) => {
        console.error('Error al obtener historial:', error);
        setMensaje('Error al cargar el historial.');
        setIsLoading(false); // Detener la carga si hay un error
      });
  }, [pacienteId]);

  // Manejar la creación o edición del historial
  const handleSubmit = (e) => {
    e.preventDefault();

    const historialData = {
      ...historial, // Usamos el estado actual del historial
      paciente: pacienteId, // Asociar el historial con el paciente
    };

    // Enviar los datos antes de la solicitud
    axios
      .post(`http://localhost:8000/api/crear-actualizar-historial-paciente/${pacienteId}/`, historialData)
      .then(() => {
        setMensaje('Historial guardado con éxito.');
        setIsEditing(false); // Cambiar a modo solo lectura después de guardar
      })
      .catch((error) => {
        console.error('Error al guardar historial:', error.response.data);
        setMensaje(`Error al guardar el historial: ${JSON.stringify(error.response.data)}`);
      });
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setHistorial({
      ...historial,
      [name]: value
    });
  };

  const handleEditClick = () => {
    setIsEditing(true); // Habilitar la edición
  };

  const handleCancelEdit = () => {
    setIsEditing(false); // Cancelar la edición
  };

  if (isLoading) {
    return <p>Cargando historial...</p>; // Mostrar mensaje de carga
  }

  return (
    <div className="historial-paciente">
      <h3 className="text-center">Historial Médico</h3>
      {mensaje && <Alert variant={mensaje.includes('éxito') ? 'success' : 'danger'}>{mensaje}</Alert>}

      <Form onSubmit={handleSubmit}>
        <Row>
          <Col md={6}>
            <Form.Group controlId="enfermedades">
              <Form.Label>Enfermedades Sistémicas</Form.Label>
              <Form.Control
                as="select"
                name="enfermedades_sistemicas"
                value={historial.enfermedades_sistemicas}
                onChange={handleInputChange}
                disabled={!isEditing} // Desactivar cuando no está en modo edición
              >
                <option value="">Seleccione una opción</option>
                <option value="Diabetes">Diabetes</option>
                <option value="Hipertensión">Hipertensión</option>
                <option value="Otra">Otra</option>
                <option value="Ninguna">Ninguna</option>
              </Form.Control>
            </Form.Group>
          </Col>

          <Col md={6}>
            <Form.Group controlId="alergias">
              <Form.Label>Alergias</Form.Label>
              <Form.Control
                as="select"
                name="alergias"
                value={historial.alergias}
                onChange={handleInputChange}
                disabled={!isEditing}
              >
                <option value="">Seleccione una opción</option>
                <option value="Medicamentos">Medicamentos</option>
                <option value="Alimentos">Alimentos</option>
                <option value="Ninguna">Ninguna</option>
              </Form.Control>
              {historial.alergias !== 'Ninguna' && (
                <Form.Control
                  type="text"
                  name="detalle_alergia"
                  placeholder="Especificar alergia"
                  value={historial.detalle_alergia}
                  onChange={handleInputChange}
                  disabled={!isEditing}
                />
              )}
            </Form.Group>
          </Col>
        </Row>

        <Row>
          <Col md={6}>
            <Form.Group controlId="tratamientos">
              <Form.Label>Tratamientos Médicos en Curso</Form.Label>
              <Form.Control
                as="select"
                name="tratamiento_medico"
                value={historial.tratamiento_medico}
                onChange={handleInputChange}
                disabled={!isEditing}
              >
                <option value="">Seleccione una opción</option>
                <option value="Si">Sí</option>
                <option value="Ninguna">Ninguna</option>
              </Form.Control>
              {historial.tratamiento_medico === 'Si' && (
                <Form.Control
                  type="text"
                  name="detalle_tratamiento"
                  placeholder="Especificar tratamiento"
                  value={historial.detalle_tratamiento}
                  onChange={handleInputChange}
                  disabled={!isEditing}
                />
              )}
            </Form.Group>
          </Col>
        </Row>

        {/* Historia Odontológica */}
        <Row>
          <Col md={6}>
            <Form.Group controlId="motivoConsulta">
              <Form.Label>Motivo de Consulta</Form.Label>
              <Form.Control
                as="select"
                name="motivo_consulta"
                value={historial.motivo_consulta}
                onChange={handleInputChange}
                disabled={!isEditing}
              >
                <option value="">Seleccione una opción</option>
                <option value="Dolor">Dolor</option>
                <option value="Revisión">Revisión</option>
                <option value="Tratamiento Estético">Tratamiento Estético</option>
              </Form.Control>
            </Form.Group>
          </Col>

          <Col md={6}>
            <Form.Group controlId="fechaConsulta">
              <Form.Label>Fecha de Consulta</Form.Label>
              <Form.Control
                type="date"
                name="fecha_consulta"
                value={historial.fecha_consulta}
                onChange={handleInputChange}
                disabled={!isEditing}
              />
            </Form.Group>
          </Col>
        </Row>

        <Row>
          <Col md={6}>
            <Form.Group controlId="tratamientosPrevios">
              <Form.Label>Tratamientos Previos</Form.Label>
              <Form.Control
                as="select"
                name="tratamientos_previos"
                value={historial.tratamientos_previos}
                onChange={handleInputChange}
                disabled={!isEditing}
              >
                <option value="">Seleccione una opción</option>
                <option value="Extracciones">Extracciones</option>
                <option value="Ortodoncia">Ortodoncia</option>
                <option value="Endodoncia">Endodoncia</option>
                <option value="Ninguna">Ninguna</option>
              </Form.Control>
            </Form.Group>
          </Col>
        </Row>

        {isEditing ? (
          <>
            <Button variant="success" type="submit" className="mt-3">
              Guardar Historial
            </Button>
            <Button variant="secondary" className="mt-3 ms-2" onClick={handleCancelEdit}>
              Cancelar
            </Button>
          </>
        ) : (
          <Button variant="primary" className="mt-3" onClick={handleEditClick}>
            Editar Historial
          </Button>
        )}
      </Form>
    </div>
  );
};

export default HistorialPaciente;
